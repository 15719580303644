import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

class ProcessBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title: "",
      content: [],
      errorMessage: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/Resources?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title: response.data.data.sub_title,
          content: response.data.data.content,
        });
    });
  }

  render() {
    return (
      <section className="resources">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 mb-md-5">
              {this.state.content.map((object, index) => (
                <div className="card mb-3" key={index}>
                  <Zoom>
                    <div className="row">
                      <div className="col-md-5">
                        <img src={object.image} className="img-fluid" alt="resources" />
                      </div>
                      <div className="col-md-7 align-self-center">
                        <div className="card-body">
                          <h5 className="card-title mb-3">{object.title}</h5>
                          <p className="card-text mb-4">{object.text}</p>
                          <a className="btn btn-primary" href={object.url} target="_blank">
                            Read Now <img alt="WTT..." src="assets/images/arrow.png" className="pl-1 img-fluid" style={{ width: "15%" }} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                </div>
              ))}
            </div>

            {/* <div className="col-md-12 mb-5 animatedParent">
                    <div className="card mb-3 animated fadeInUp">
                        <div className="row">
                            <div className="col-md-5">
                                <img alt="WTT..." src="assets/images/resources-2.png" className="img-fluid" alt="resources" />
                            </div>
                            <div className="col-md-7 align-self-center">
                                <div className="card-body">
                                    <h5 className="card-title mb-3">Coronavirus: Tests at sewage plants aim to get 'head start' on detecting new COVID-19 outbreaks</h5>
                                    <p className="card-text mb-4">A very first stage of water filtration system through the sand
                                        bed for the removal of Suspended solids from the biological treatment.</p>
                                    <a className="btn btn-primary">Read Now <img alt="WTT..." src="assets/images/arrow.png" className="pl-1 img-fluid" style={{width:'15%'}} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-5 animatedParent">
                    <div className="card mb-3 animated fadeInUp">
                        <div className="row">
                            <div className="col-md-5">
                                <img alt="WTT..." src="assets/images/resources-3.png" className="img-fluid" alt="resources" />
                            </div>
                            <div className="col-md-7 align-self-center">
                                <div className="card-body">
                                    <h5 className="card-title mb-3">3 Hazards at Your Water/wastewater Treatment Plant</h5>
                                    <p className="card-text mb-4">A very first stage of water filtration system through the sand
                                        bed for the removal of Suspended solids from the biological treatment.</p>
                                    <a className="btn btn-primary">Read Now <img alt="WTT..." src="assets/images/arrow.png" className="pl-1 img-fluid" style={{width:'15%'}} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-5 animatedParent">
                    <div className="card mb-3 animated fadeInUp">
                        <div className="row">
                            <div className="col-md-5">
                                <img alt="WTT..." src="assets/images/resources-1.png" className="img-fluid" alt="resources" />
                            </div>
                            <div className="col-md-7 align-self-center">
                                <div className="card-body">
                                    <h5 className="card-title mb-3">How does drinking water treatment plant work?</h5>
                                    <p className="card-text mb-4">A very first stage of water filtration system through the sand
                                        bed for the removal of Suspended solids from the biological treatment.</p>
                                    <a className="btn btn-primary">Read Now <img alt="WTT..." src="assets/images/arrow.png" className="pl-1 img-fluid" style={{width:'15%'}} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
          </div>

          {/* <a className="next" href="">
            {" "}
            Next<i className="pl-2 far fa-arrow-right"></i>
          </a> */}
        </div>
      </section>
    );
  }
}

export default ProcessBanner;
