import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";
import '../../node_modules/photoswipe/src/photoswipe.css';
import PhotoSwipeLightbox from '../../node_modules/photoswipe/src/js/lightbox/lightbox.js';
class GalleryImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image_data: [],
      errorMessage: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/findParticularGallery?language=English").then((response) => {
      if (response.data)
        this.setState({
          image_data: response.data.data.img,
        });
    });
  }

  render() {
    const lightbox = new PhotoSwipeLightbox({
      gallery: "#my-gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();
    return (
      <section className="gallery">
        <div className="container mt-5">
          <div className="pswp-gallery" id="my-gallery">
            <div className="row justify-content-center">
              {this.state.image_data.map((image, index) => (
                <Zoom>
                  <div className="col-md-4 mb-5 animatedParent" key={index}>
                    <a href={image.imgUrl} title={image.title} target="_blank" data-pswp-width="1500" data-pswp-height="1000">
                      <img src={image.imgUrl} alt="" className="img-fluid gallery-img" />
                    </a>
                  </div>
                </Zoom>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default GalleryImages;
