import React from "react";
import axios from "axios";

class AwardsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title: "",
      content: [],
      errorMessage: "",
    };
  }

  componentDidMount() {
    axios.get("/wtt/Awards?language=English").then((response) => {
      if (response.data)
        this.setState({
          content: response.data.data.awards,
        });
    });
  }

  render() {
    return (
      <div className="container-fluid p-0 m-0">
        {this.state.content.map((object, index) => (
          <div className={index % 2 ? "animatedParent first-backgrund py-5" : "animatedParent  secondbackground py-5"} key={index}>
            {/* <h2 className=" animated fadeInLeft go">{object.title}</h2> */}
            <div className="row mt-3 mt-md-0 ">
              <div className={index % 2 ? "col-md-7 order-md-2 align-self-center animated fadeInLeft go " : "col-md-7 order-md-1 align-self-center animated fadeInLeft go "}>
                <h4>{object.title}</h4>
                <p>{object.description}</p>
              </div>
              <div className={index % 2 ? "col-md-5 order-md-1" : "col-md-5 order-md-2"}>
                <div className="animated fadeInUp go">
                  <img src={object.image} className="img-fluid card p-4" alt="csr" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default AwardsContent;
