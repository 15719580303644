import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// project imports
import MainLayout from "./../layout/MainLayout";
import Loadable from "../ui-component/Loadable";
import AuthGuard from "./../utils/route-guard/AuthGuard";

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import("../views/dashboard/Default")));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import("../views/utilities/Typography")));
const UtilsColor = Loadable(lazy(() => import("../views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("../views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(lazy(() => import("../views/utilities/MaterialIcons")));
const UtilsTablerIcons = Loadable(lazy(() => import("../views/utilities/TablerIcons")));
// sample page routing
const SamplePage = Loadable(lazy(() => import("../views/sample-page")));

//GlobalReferences
const GlobalReferences = Loadable(lazy(() => import("../views/GlobalReferences")));
const CreateGlobalReferences = Loadable(lazy(() => import("../views/GlobalReferences/CreateGlobalReferences")));
const UpdateGlobalReferences = Loadable(lazy(() => import("../views/GlobalReferences/UpdateGlobalReferences")));

//RD
const RD = Loadable(lazy(() => import("../views/R-D")));
const CreateRD = Loadable(lazy(() => import("../views/R-D/CreateRD")));
const UpdateRD = Loadable(lazy(() => import("../views/R-D/UpdateRD")));

//Resources
const Resources = Loadable(lazy(() => import("../views/Resources")));
const CreateResources = Loadable(lazy(() => import("../views/Resources/CreateResources")));
const UpdateResources = Loadable(lazy(() => import("../views/Resources/UpdateResources")));

//Testimonials
const Testimonials = Loadable(lazy(() => import("../views/Testimonials")));
const CreateTestimonials = Loadable(lazy(() => import("../views/Testimonials/CreateTestimonials")));
const UpdateTestimonials = Loadable(lazy(() => import("../views/Testimonials/UpdateTestimonials")));

//Gallery
const Gallery = Loadable(lazy(() => import("../views/Gallery")));
const CreateGallery = Loadable(lazy(() => import("../views/Gallery/CreateGallery")));
const UpdateGallery = Loadable(lazy(() => import("../views/Gallery/UpdateGallery")));

//Career
const Career = Loadable(lazy(() => import("../views/Career")));
const CreateCareer = Loadable(lazy(() => import("../views/Career/CreateCareer")));
const UpdateCareer = Loadable(lazy(() => import("../views/Career/UpdateCareer")));

//NewsMedia
const NewsMedia = Loadable(lazy(() => import("../views/News-Media")));
const CreateNewsMedia = Loadable(lazy(() => import("../views/News-Media/CreateNewsMedia")));
const UpdateNewsMedia = Loadable(lazy(() => import("../views/News-Media/UpdateNewsMedia")));

//Solutions
const Solutions = Loadable(lazy(() => import("../views/Solutions")));
const UpdateSolutions = Loadable(lazy(() => import("../views/Solutions/UpdateSolutions")));
const CreateSolutions = Loadable(lazy(() => import("../views/Solutions/CreateSolutions")));

//CSR

const CSR = Loadable(lazy(() => import("../views/CSR")));
const CreateCSR = Loadable(lazy(() => import("../views/CSR/CreateCSR")));
const UpdateCSR = Loadable(lazy(() => import("../views/CSR/UpdateCSR")));

//Industries We  Serve

const IndustriesWeServe = Loadable(lazy(() => import("../views/Industries")));
const CreateIndustriesWeServe = Loadable(lazy(() => import("../views/Industries/CreateIndustriesWeServe")));
const UpdateIndustriesWeServe = Loadable(lazy(() => import("../views/Industries/UpdateIndustriesWeServe")));

//Language

const Languages = Loadable(lazy(() => import("../views/Languages")));
const CreateLanguage = Loadable(lazy(() => import("../views/Languages/CreateLanguage")));

//Explore WTT

const ExploreWTT = Loadable(lazy(() => import("../views/ExploreWTT")));
const CreateExploreWTT = Loadable(lazy(() => import("../views/ExploreWTT/CreateExploreWTT")));
const UpdateExploreWTT = Loadable(lazy(() => import("../views/ExploreWTT/UpdateExploreWTT")));

//Products

const Products = Loadable(lazy(() => import("../views/Products")));
const CreateProducts = Loadable(lazy(() => import("../views/Products/CreateProducts")));
const UpdateProducts = Loadable(lazy(() => import("../views/Products/UpdateProducts")));

//Services

const Services = Loadable(lazy(() => import("../views/Services")));
const CreateServices = Loadable(lazy(() => import("../views/Services/CreateServices")));
const UpdateServices = Loadable(lazy(() => import("../views/Services/UpdateServices")));

//Touch With US

const TouchWithUs = Loadable(lazy(() => import("../views/TouchWithUs")));
const CreateTouchWithUs = Loadable(lazy(() => import("../views/TouchWithUs/CreateTouchWithUs")));
const UpdateTouchWithUs = Loadable(lazy(() => import("../views/TouchWithUs/UpdateTouchWithUs")));

//Home

const Home = Loadable(lazy(() => import("../views/Home")));
const CreateHome = Loadable(lazy(() => import("../views/Home/CreateHome")));
const UpdateHome = Loadable(lazy(() => import("../views/Home/UpdateHome")));

//Admin

const Admins = Loadable(lazy(() => import("../views/Admins")));
const CreateAdmin = Loadable(lazy(() => import("../views/Admins/CreateAdmin")));
const UpdateAdmin = Loadable(lazy(() => import("../views/Admins/UpdateAdmin")));
const AdminDetails = Loadable(lazy(() => import("../views/Admins/AdminDetails")));
const UpdatePassword = Loadable(lazy(() => import("../views/Admins/UpdatePassword")));

//Header

const Header = Loadable(lazy(() => import("../views/Header")));
const CreateHeader = Loadable(lazy(() => import("../views/Header/CreateHeader")));
const UpdateHeader = Loadable(lazy(() => import("../views/Header/UpdateHeader")));

//Footer

const Footer = Loadable(lazy(() => import("../views/Footer")));
const CreateFooter = Loadable(lazy(() => import("../views/Footer/CreateFooter")));
const UpdateFooter = Loadable(lazy(() => import("../views/Footer/UpdateFooter")));

//Client Image

const ClientImage = Loadable(lazy(() => import("../views/Clients")));
const CreateClients = Loadable(lazy(() => import("../views/Clients/CreateClients")));
const UpdateClients = Loadable(lazy(() => import("../views/Clients/UpdateClients")));

//Customer table

const CustomerTable = Loadable(lazy(() => import("../views/CustomerTable")));
const CreateCustomerTable = Loadable(lazy(() => import("../views/CustomerTable/CreateCustomerTable")));
const UpdateCustomerTable = Loadable(lazy(() => import("../views/CustomerTable/UpdateCustomerTable")));

//QAS

const QAsIndex = Loadable(lazy(() => import("../views/QAS")));
const CreateQAs = Loadable(lazy(() => import("../views/QAS/CreateQAs")));
const UpdateQAs = Loadable(lazy(() => import("../views/QAS/UpdateQAs")));

// Awards

const Awards = Loadable(lazy(() => import("../views/Awards")));
const CreateAwards = Loadable(lazy(() => import("../views/Awards/CreateAwards")));
const UpdateAwards = Loadable(lazy(() => import("../views/Awards/UpdateAwards")));

// Logo

const Logo = Loadable(lazy(() => import("../views/Logo")));
const UpdateLogo = Loadable(lazy(() => import("../views/Logo/UpdateLogo")));

//Brochure

const Brochure = Loadable(lazy(() => import("../views/Brochure")));
const CreateBrochure = Loadable(lazy(() => import("../views/Brochure/CreateBrochure")));
const UpdateBrochure = Loadable(lazy(() => import("../views/Brochure/UpdateBrochure")));

//WorldwidePresence

const WorldwidePresence = Loadable(lazy(() => import("../views/WorldwidePresence")));
const EditWorldwidePresence = Loadable(lazy(() => import("../views/WorldwidePresence/EditWorldwidePresence")));

const SEOTags = Loadable(lazy(() => import("../views/SEO")));
const CreateSEO = Loadable(lazy(() => import("../views/SEO/CreateSEO")));
const UpdateSEO = Loadable(lazy(() => import("../views/SEO/UpdateSEO")));


//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        "/Admin/dashboard/default",
        "/utils/util-typography",
        "/utils/util-color",
        "/utils/util-shadow",
        "/icons/tabler-icons",
        "/icons/material-icons",
        "/sample-page",

        "/Admin/GlobalReferences",
        "/Admin/CreateGlobalReferences",
        "/Admin/UpdateGlobalReferences",

        "/Admin/RD",
        "/Admin/CreateRD",
        "/Admin/UpdateRD",

        "/Admin/Resources",
        "/Admin/CreateResources",
        "/Admin/UpdateResources",

        "/Admin/Testimonials",
        "/Admin/CreateTestimonials",
        "/Admin/UpdateTestimonials",

        "/Admin/Gallery",
        "/Admin/CreateGallery",
        "/Admin/UpdateGallery",

        "/Admin/Career",
        "/Admin/CreateCareer",
        "/Admin/UpdateCareer",

        "/Admin/CSR",
        "/Admin/CreateCSR",
        "/Admin/UpdateCSR",

        "/Admin/IndustriesWeServe",
        "/Admin/CreateIndustriesWeServe",
        "/Admin/UpdateIndustriesWeServe",

        "/Admin/Solutions",
        "/Admin/CreateSolutions",
        "/Admin/UpdateSolutions",

        "/Admin/NewsMedia",
        "/Admin/CreateNewsMedia",
        "/Admin/UpdateNewsMedia",

        "/Admin/Languages",
        "/Admin/CreateLanguage",

        "/Admin/ExploreWTT",
        "/Admin/CreateExploreWTT",
        "/Admin/UpdateExploreWTT",

        "/Admin/Products",
        "/Admin/CreateProducts",
        "/Admin/UpdateProducts",

        "/Admin/Services",
        "/Admin/CreateServices",
        "/Admin/UpdateServices",

        "/Admin/TouchWithUs",
        "/Admin/CreateTouchWithUs",
        "/Admin/UpdateTouchWithUs",

        "/Admin/Home",
        "/Admin/CreateHome",
        "/Admin/UpdateHome",

        "/Admin/Admins",
        "/Admin/CreateAdmin",
        "/Admin/UpdateAdmin",
        "/Admin/AdminDetails",
        "/Admin/UpdatePassword",

        "/Admin/Footer",
        "/Admin/CreateFooter",
        "/Admin/UpdateFooter",

        "/Admin/Header",
        "/Admin/CreateHeader",
        "/Admin/UpdateHeader",

        "/Admin/ClientImage",
        "/Admin/CreateClients",
        "/Admin/UpdateClients",

        "/Admin/CustomerTableIndex",
        "/Admin/CreateCustomerTable",
        "/Admin/UpdateCustomerTable",

        "/Admin/QAsIndex",
        "/Admin/CreateQAs",
        "/Admin/UpdateQAs",

        "/Admin/AwardsIndex",
        "/Admin/CreateAwards",
        "/Admin/UpdateAwards",

        "/Admin/Logo",
        "/Admin/UpdateLogo",

        "/Admin/Brochure",
        "/Admin/CreateBrochure",
        "/Admin/UpdateBrochure",

        "/Admin/WorldwidePresence",
        "/Admin/EditWorldwidePresence",

        "/Admin/SEOTags",
        "/Admin/CreateSEOTags",
        "/Admin/UpdateSEOTags",
      ]}
    >
      <MainLayout>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            <Route path="/Admin/dashboard/default" component={DashboardDefault} />

            <Route path="/utils/util-typography" component={UtilsTypography} />
            <Route path="/utils/util-color" component={UtilsColor} />
            <Route path="/utils/util-shadow" component={UtilsShadow} />
            <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
            <Route path="/icons/material-icons" component={UtilsMaterialIcons} />

            <Route path="/sample-page" component={SamplePage} />

            <Route path="/Admin/GlobalReferences" component={GlobalReferences} />
            <Route path="/Admin/CreateGlobalReferences" component={CreateGlobalReferences} />
            <Route path="/Admin/UpdateGlobalReferences/:language" component={UpdateGlobalReferences} />

            <Route path="/Admin/RD" component={RD} />
            <Route path="/Admin/CreateRD" component={CreateRD} />
            <Route path="/Admin/UpdateRD/:language" component={UpdateRD} />

            <Route path="/Admin/Resources" component={Resources} />
            <Route path="/Admin/CreateResources" component={CreateResources} />
            <Route path="/Admin/UpdateResources/:language" component={UpdateResources} />

            <Route path="/Admin/Testimonials" component={Testimonials} />
            <Route path="/Admin/CreateTestimonials" component={CreateTestimonials} />
            <Route path="/Admin/UpdateTestimonials/:language" component={UpdateTestimonials} />

            <Route path="/Admin/Gallery" component={Gallery} />
            <Route path="/Admin/CreateGallery" component={CreateGallery} />
            <Route path="/Admin/UpdateGallery/:language" component={UpdateGallery} />

            <Route path="/Admin/Career" component={Career} />
            <Route path="/Admin/CreateCareer" component={CreateCareer} />
            <Route path="/Admin/UpdateCareer/:language" component={UpdateCareer} />

            <Route path="/Admin/NewsMedia" component={NewsMedia} />
            <Route path="/Admin/CreateNewsMedia" component={CreateNewsMedia} />
            <Route path="/Admin/UpdateNewsMedia/:language" component={UpdateNewsMedia} />

            <Route path="/Admin/Solutions" component={Solutions} />
            <Route path="/Admin/CreateSolutions" component={CreateSolutions} />
            <Route path="/Admin/UpdateSolutions/:language" component={UpdateSolutions} />

            <Route path="/Admin/CSR" component={CSR} />
            <Route path="/Admin/CreateCSR" component={CreateCSR} />
            <Route path="/Admin/UpdateCSR/:language" component={UpdateCSR} />

            <Route path="/Admin/IndustriesWeServe" component={IndustriesWeServe} />
            <Route path="/Admin/CreateIndustriesWeServe" component={CreateIndustriesWeServe} />
            <Route path="/Admin/UpdateIndustriesWeServe/:language" component={UpdateIndustriesWeServe} />

            <Route path="/Admin/Languages" component={Languages} />
            <Route path="/Admin/CreateLanguage" component={CreateLanguage} />

            <Route path="/Admin/ExploreWTT" component={ExploreWTT} />
            <Route path="/Admin/CreateExploreWTT" component={CreateExploreWTT} />
            <Route path="/Admin/UpdateExploreWTT/:language" component={UpdateExploreWTT} />

            <Route path="/Admin/Products" component={Products} />
            <Route path="/Admin/CreateProducts" component={CreateProducts} />
            <Route path="/Admin/UpdateProducts/:language" component={UpdateProducts} />

            <Route path="/Admin/Services" component={Services} />
            <Route path="/Admin/CreateServices" component={CreateServices} />
            <Route path="/Admin/UpdateServices/:language" component={UpdateServices} />

            <Route path="/Admin/TouchWithUs" component={TouchWithUs} />
            <Route path="/Admin/CreateTouchWithUs" component={CreateTouchWithUs} />
            <Route path="/Admin/UpdateTouchWithUs/:language" component={UpdateTouchWithUs} />

            <Route path="/Admin/Home" component={Home} />
            <Route path="/Admin/CreateHome" component={CreateHome} />
            <Route path="/Admin/UpdateHome/:language" component={UpdateHome} />

            <Route path="/Admin/Header" component={Header} />
            <Route path="/Admin/CreateHeader" component={CreateHeader} />
            <Route path="/Admin/UpdateHeader/:language" component={UpdateHeader} />

            <Route path="/Admin/Footer" component={Footer} />
            <Route path="/Admin/CreateFooter" component={CreateFooter} />
            <Route path="/Admin/UpdateFooter/:language" component={UpdateFooter} />

            <Route path="/Admin/Admins" component={Admins} />
            <Route path="/Admin/CreateAdmin" component={CreateAdmin} />
            <Route path="/Admin/UpdateAdmin" component={UpdateAdmin} />

            <Route path="/Admin/UpdatePassword" component={UpdatePassword} />
            <Route path="/Admin/AdminDetails/:email" component={AdminDetails} />

            <Route path="/Admin/ClientImage" component={ClientImage} />
            <Route path="/Admin/CreateClients" component={CreateClients} />
            <Route path="/Admin/UpdateClients/:ID" component={UpdateClients} />

            <Route path="/Admin/CustomerTableIndex" component={CustomerTable} />
            <Route path="/Admin/CreateCustomerTable" component={CreateCustomerTable} />
            <Route path="/Admin/UpdateCustomerTable/:ID" component={UpdateCustomerTable} />

            <Route path="/Admin/QAsIndex" component={QAsIndex} />
            <Route path="/Admin/CreateQAs" component={CreateQAs} />
            <Route path="/Admin/UpdateQAs/:ID" component={UpdateQAs} />

            <Route path="/Admin/AwardsIndex" component={Awards} />
            <Route path="/Admin/CreateAwards" component={CreateAwards} />
            <Route path="/Admin/UpdateAwards/:language" component={UpdateAwards} />

            <Route path="/Admin/Logo" component={Logo} />
            <Route path="/Admin/UpdateLogo" component={UpdateLogo} />

            <Route path="/Admin/Brochure" component={Brochure} />
            <Route path="/Admin/CreateBrochure" component={CreateBrochure} />
            <Route path="/Admin/UpdateBrochure" component={UpdateBrochure} />

            <Route path="/Admin/WorldwidePresence" component={WorldwidePresence} />
            <Route path="/Admin/EditWorldwidePresence" component={EditWorldwidePresence} />

            <Route path="/Admin/SEOTags" component={SEOTags} />
            <Route path="/Admin/CreateSEOTags" component={CreateSEO} />
            <Route path="/Admin/UpdateSEOTags/:id" component={UpdateSEO} />



          </AuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
