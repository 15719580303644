import React from "react";
import axios from "axios";

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLine: [],
    };
  }

  componentDidMount() {
    axios.get("/wtt/findParticularTeams?language=English").then((response) => {
      if (response.data) {
        this.setState({
          timeLine: response.data.data.timeLine,
        });
      }
    });
  }

  render() {
    return (
      <div className="history animatedParent">
        <div className="container">
          <h2 className="text-left animated fadeInLeft">History</h2>
          <div className="d-flex justify-content-start align-items-start justify-md-content-center align-md-items-center flex-column animated fadeInUp">
            {this.state.timeLine.map((item, index) => {
              return (
                <div className="timeline d-flex align-items-center" key={index}>
                  <h3>{item.year}</h3>
                  <div className="timeline-card">
                    <h4>{item.title}</h4>
                    <p>{item.desription}</p>
                  </div>
                </div>
              );
            })}
            {/* <div className="timeline d-flex align-items-center">
                        <h3>
                            2004
                        </h3>
                        <div className="timeline-card">
                            <h4>
                                Service Provider
                            </h4>
                            <p>
                                Started as service provider for ZLD Projects in 2004
                            </p>
                        </div>
                    </div>
                    <div className="timeline d-flex align-items-center">
                        <h3>
                            2008
                        </h3>
                        <div className="timeline-card">
                            <h4>
                                Solution Provider
                            </h4>
                            <p>
                                WTT Technology Services India Pvt. Ltd. was registered as a company and upgraded as a complete solution provider in 2008
                            </p>
                        </div>
                    </div>
                    <div className="timeline d-flex align-items-center">
                        <h3>
                            2017
                        </h3>
                        <div className="timeline-card">
                            <h4>
                                WTT FZC
                            </h4>
                            <p>
                                Water Treatment Technology FZC was started in Dubai in 2017
                            </p>
                        </div>
                    </div>
                    <div className="timeline d-flex align-items-center">
                        <h3>
                            2018
                        </h3>
                        <div className="timeline-card">
                            <h4>
                                WTT ITALIA SRL
                            </h4>
                            <p>
                            WTT Italia SRL was stared in Italy in 2018
                            </p>
                        </div>
                    </div>
                    <div className="timeline d-flex align-items-center">
                        <h3>
                            2022
                        </h3>
                        <div className="timeline-card">
                            <h4>
                                Targeting Global
                            </h4>
                            <p>
                                Re- Branded as WTT International in 2022
                            </p>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;
