import React from "react";
import "../App.css";
import Header from "../Header";
import Footer from "../Footer";
import Page from "../WTTLoading";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title_content: [],
      Videos: [],
    };
  }
  componentDidMount() {
    axios.get("/wtt/CSR?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title_content: response.data.data.sub_title_type[this.props.match.params.sub_title_type]["content"][this.props.match.params.content],
          Videos: response.data.data.sub_title_type[this.props.match.params.sub_title_type]["content"][this.props.match.params.content]["multipleImages"],
        });
    });
  }
  render() {
    const options = {
      loop: true,
      nav: false,
      navText: ["<i className='fa fa-chevron-left'></i>", "<i className='fa fa-chevron-right'></i>"],
      dots: true,
      autoplay: true,
      margin: 30,
      autoplayTimeout: 4500,
      autoplaySpeed: 1000,
      lazyLoad: true,
      // singleitem: true,
      items: 3,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 1,
        },
        1100: {
          items: 1,
        },
      },
    };
    let Data = [];
    let { sub_title_content, Videos } = this.state;
    Videos.map((item, index) => {
      const temp = (
        <img
          style={{
            backgroundColor: "white",
            objectFit: "contain",
            borderRadius: "10px",
          }}
          alt="WTT"
          key={index}
          src={item}
        />
      );
      Data.push(temp);
      return temp;
    });
    return (
      <div className="App">
        <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
          <Header />
          <div className="container csr">
            <div className="row pt-5">
              <div className="col-md-6">
                <div>
                  <h2>{sub_title_content.title}</h2>
                  <p className="mt-4">{sub_title_content.description} </p>
                </div>
              </div>
              <div className="col-md-6">
                {Data.length && (
                  <OwlCarousel className="owl-theme" {...options}>
                    {Data}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </Page>
      </div>
    );
  }
}

export default App;
