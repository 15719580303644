import React from "react";
import axios from "axios";
import Slide from "react-reveal/Slide";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class QAs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alltestimonials: [],
    };
  }
  componentDidMount() {
    axios.get("/wtt/findAllQuestionByLanguage?language=English").then((response) => {
      if (response.data)
        this.setState({
          alltestimonials: response.data.data,
        });
    });
  }
  render() {
    const options = {
      loop: true,
      nav: true,
      navText: ["<i className='fa fa-chevron-left'></i>", "<i className='fa fa-chevron-right'></i>"],
      dots: false,
      autoplay: true,
      margin: 30,
      autoplayTimeout: 4500,
      autoplaySpeed: 1000,
      lazyLoad: true,
      // singleitem: true,
      items: 3,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 1,
        },
        1100: {
          items: 3,
        },
      },
    };
    var Testimonials = [];
    for (let index = 0; index < this.state.alltestimonials.length; index++) {
      const element = (
        <div className="card mb-4" data-aos="fade-right" key={index + index * 33} data-aos-delay={(index * 100).toString()}>
          <div className="card-header collapsed" data-toggle="collapse" key={index} href={`#collapse${index}`}>
            <a className="card-title">{this.state.alltestimonials[index].question}</a>
          </div>
          <div id={`collapse${index}`} className="card-body collapse" data-parent="#accordion">
            <p>{this.state.alltestimonials[index].answer}</p>
          </div>
        </div>
      );
      Testimonials.push(element);
    }

    return (
      <section className="testimonial faq animatedParent">
        {Testimonials.length ? (
          <div className="container">
            <h2 className="animated fadeInLeft">QAs</h2>
            {/* <div className="row mt-md-5">
              {Testimonials.length ? (
                <OwlCarousel className="owl-theme" {...options}>
                  {Testimonials}
                </OwlCarousel>
              ) : null}
            </div> */}

            <div className="row flex-column-reverse flex-md-row justify-content-center mt-5">
              <div className="col-md-9">
                <div id="accordion" className="accordion">
                  {Testimonials}
                  {/* <div className="card mb-4" data-aos="fade-right">
                    <div className="card-header collapsed" data-toggle="collapse" href="#collapseOne">
                      <a className="card-title">Lorem Ipsum is simply dummy text of the printing</a>
                    </div>
                    <div id="collapseOne" className="card-body collapse" data-parent="#accordion">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                  </div>
                  <div className="card mb-4" data-aos="fade-right" data-aos-delay="100">
                    <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                      <a className="card-title">Lorem Ipsum is simply dummy text of the printing</a>
                    </div>
                    <div id="collapseTwo" className="card-body collapse" data-parent="#accordion">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                  </div>
                  <div className="card mb-4" data-aos="fade-right" data-aos-delay="200">
                    <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                      <a className="card-title">Lorem Ipsum is simply dummy text of the printing</a>
                    </div>
                    <div id="collapseThree" className="card-body collapse" data-parent="#accordion">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                  </div>
                  <div className="card mb-4" data-aos="fade-right" data-aos-delay="300">
                    <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour">
                      <a className="card-title">What’s is academy21?</a>
                    </div>
                    <div id="collapseFour" className="card-body collapse" data-parent="#accordion">
                      <p>This is a revolutionary educational initiative geared up to make the world of education a more colourful journey for our young generation.</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default QAs;
