import React from 'react';
import './App.css';
import Header from './Header'  
import Footer from './Footer' 
import CareerBanner from './Career/CareerBanner' 
import CareerContent from './Career/CareerContent' 
import Page from "./WTTLoading"

class App extends React.Component {
 render(){ 
   return (
    <div className="App">
      <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
      <Header/>      
      <CareerBanner />
      <CareerContent />
      <Footer/>
      </Page>
    </div>
  );
}
}


export default App;
