import React from "react";
import Zoom from "react-reveal/Zoom";

class ClientsInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <section className="customer-inner animatedParent py-5">
          <div className="container">
            <h3 className="text-center mb-5">Clients</h3>
            <div className="row text-center">
              <div className="col-md-3 mb-3">
                <Zoom>
                  <img src="assets/images/logo.png" className="img-fluid" />
                </Zoom>
              </div>
              <div className="col-md-3 mb-3">
                <Zoom>
                  <img src="assets/images/logo.png" className="img-fluid" />
                </Zoom>
              </div>
              <div className="col-md-3 mb-3">
                <Zoom>
                  <img src="assets/images/logo.png" className="img-fluid" />
                </Zoom>
              </div>
              <div className="col-md-3 mb-3">
                <Zoom>
                  <img src="assets/images/logo.png" className="img-fluid" />
                </Zoom>
              </div>
              <div className="col-md-3 mb-3">
                <Zoom>
                  <img src="assets/images/logo.png" className="img-fluid" />
                </Zoom>
              </div>
              <div className="col-md-3 mb-3">
                <Zoom>
                  <img src="assets/images/logo.png" className="img-fluid" />
                </Zoom>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ClientsInner;
