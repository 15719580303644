import React from "react";
import Zoom from "react-reveal/Zoom";

class IndustriesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <section className="customer-inner animatedParent py-5">
          <div className="container">
            <h3 className="text-center mb-5">Customer</h3>
            <div className="row">
              {/* <Zoom> */}
              <div className="col-md-3 my-5">
                <Zoom>
                  <div className="customer" id="hexagon">
                    <div className="test-card1">
                      <h3>Nagajothi</h3>
                      <p>
                        Ramanathapuram, <br />
                        Tamilnadu
                      </p>
                    </div>
                  </div>
                </Zoom>
              </div>
              <div className="col-md-3 my-5">
                <Zoom>
                  <div className="customer" id="hexagon">
                    <div className="test-card1">
                      <h3>Nagajothi</h3>
                      <p>
                        Ramanathapuram, <br />
                        Tamilnadu
                      </p>
                    </div>
                  </div>
                </Zoom>
              </div>
              <div className="col-md-3 my-5">
                <Zoom>
                  <div className="customer" id="hexagon">
                    <div className="test-card1">
                      <h3>Nagajothi</h3>
                      <p>
                        Ramanathapuram, <br />
                        Tamilnadu
                      </p>
                    </div>
                  </div>
                </Zoom>
              </div>
              <div className="col-md-3 my-5">
                <Zoom>
                  <div className="customer" id="hexagon">
                    <div className="test-card1">
                      <h3>Nagajothi</h3>
                      <p>
                        Ramanathapuram, <br />
                        Tamilnadu
                      </p>
                    </div>
                  </div>
                </Zoom>
              </div>
              <div className="col-md-3 my-5">
                <Zoom>
                  <div className="customer" id="hexagon">
                    <div className="test-card1">
                      <h3>Nagajothi</h3>
                      <p>
                        Ramanathapuram, <br />
                        Tamilnadu
                      </p>
                    </div>
                  </div>
                </Zoom>
              </div>
              <div className="col-md-3 my-5">
                <Zoom>
                  <div className="customer" id="hexagon">
                    <div className="test-card1">
                      <h3>Nagajothi</h3>
                      <p>
                        Ramanathapuram, <br />
                        Tamilnadu
                      </p>
                    </div>
                  </div>
                </Zoom>
              </div>
              <div className="col-md-3 my-5">
                <Zoom>
                  <div className="customer" id="hexagon">
                    <div className="test-card1">
                      <h3>Nagajothi</h3>
                      <p>
                        Ramanathapuram, <br />
                        Tamilnadu
                      </p>
                    </div>
                  </div>
                </Zoom>
              </div>
              <div className="col-md-3 my-5">
                <Zoom>
                  <div className="customer" id="hexagon">
                    <div className="test-card1">
                      <h3>Nagajothi</h3>
                      <p>
                        Ramanathapuram, <br />
                        Tamilnadu
                      </p>
                    </div>
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default IndustriesList;
