import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import HomeBanner from './HomeBanner';
import RoundeCircle from './RoundeCircle';
import HomeMap from './HomeMap';
import HomeTest from './HomeTest';
import HomeClients from './HomeClients';
import HomeNews from './HomeNews';
import Clients from './Clients';
import CustomerTable from './CustomerTable';
import QAs from './QAs';
import Page from "../WTTLoading";

class App extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className='App '>
        <Page loader={'spin'} color={'#A9A9A9'} size={8}>
          <Header />
          <HomeBanner />
          <RoundeCircle />
          <Clients />
          <HomeMap />
          <HomeTest />
          <HomeNews />
          {/* <HomeTestimonial /> */}
          <HomeClients />
          <QAs />
          <CustomerTable />
          <Footer />
        </Page>
      </div>
    );
  }
}

export default App;
