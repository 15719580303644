import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: [],
      typeContent: [],
      indexValue: 0,
      Show: true,
      TempTitle: "",
      TempContent: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/Products?language=English").then((response) => {
      if (response.data)
        this.setState({
          type: response.data.data.type,
          typeContent: response.data.data.typeContent,
        });
    });
  }

  handleTabClick = (i) => {
    if (this.state.indexValue !== i) {
      this.setState({
        indexValue: i,
      });
    }
  };
  render() {
    return (
      <>
        <section className="products animatedParent">
          {this.state.type.length ? (
            <div className="container-fluid">
              <div className="accordion" id="accordionExample">
                <div className="row text-center no-gutters justify-content-center">
                  {this.state.type.map((TypeName, index) => {
                    return (
                      <div className="col-md-2 col-4 mb-3">
                        <div className="card">
                          <div className="card-header" id={"heading" + index}>
                            <h6 className="mb-0">
                              <a
                                className={index === this.state.indexValue ? "product-button active" : "product-button"}
                                onClick={(event) => {
                                  this.handleTabClick(index);
                                }}
                                type="button"
                                data-toggle="collapse"
                                aria-controls={"collapse" + index}
                                data-target={index === this.state.indexValue ? "#collapse" + this.state.indexValue : ""}
                                aria-expanded={index === 0 ? true : false}
                              >
                                {TypeName}
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="py-5">
                  <div className="row animatedParent">
                    {this.state.typeContent[this.state.type[this.state.indexValue]].map((Data, i) => {
                      return (
                        <Zoom>
                          <div key={i} className={(i + this.state.indexValue) % 2 ? "col-md-4 px-4 mb-5 animated fadeInUp go" : "col-md-4 px-4 mb-5 animated fadeInUp go"}>
                            <div className="content">
                              <button
                                onClick={() => {
                                  this.setState({
                                    TempTitle: Data.title,
                                    TempContent: Data.description,
                                  });
                                }}
                                className="btn"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                <div className="content-overlay"></div>
                                <img alt="WTT..." className="content-image img-fluid" src={Data.image} />
                                <p className="text-center">{Data.title}</p>
                                <div className="content-details fadeIn-top go">
                                  <h5 className="content-title">
                                    Explore now
                                    <img alt="WTT..." src="../assets/images/arrow.png" className="img-fluid" style={{ width: "1rem" }} />
                                  </h5>
                                </div>
                              </button>
                            </div>
                          </div>
                        </Zoom>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </section>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="content">
                <h2>{this.state.TempTitle}</h2>
                <p>{this.state.TempContent}</p>
              </div>
              <div className="text-right">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <img alt="WTT..." src="assets/images/close.png" className="img-fluid" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductList;
