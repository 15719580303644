import React from "react";
import axios from "axios";

class GlobalBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalReactPackages: [],
      errorMessage: "",
      title: null,
      background_image: null,
    };
  }
  componentDidMount() {
    axios.get("/wtt/GlobalReferences?language=English").then((response) => {
      if (response.data)
        this.setState({
          title: response.data.data.title,
          background_image: response.data.data.background_image,
        });
    });
  }
  render() {
    return (
      <section className="hero-banner1" style={{ background: "url(" + this.state.background_image + ")" }}>
        <div className="container">
          <div className="pt-5">
            <div className="row pt-md-5 mt-md-5">
              <div className="col-md-5 py-5 pb-3">
                <div className="global-content">
                  <h1>{this.state.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default GlobalBanner;
