import React from "react";
import axios from "axios";

class AllCustomerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alltestimonials: [],
    };
  }
  componentDidMount() {
    axios.get("/wtt/findAllCetpCustomerByLanguage?language=English").then((response) => {
      if (response.data)
        this.setState({
          alltestimonials: response.data.data,
        });
    });
  }
  render() {
    let { alltestimonials } = this.state;
    return (
      <section className="animatedParent">
        {alltestimonials.length ? (
          <div className="container">
            <div className="py-5 mt-5">
              <div className="news-title animated fadeInLeft my-5">
                <h2 className="animated fadeInLeft">Customers</h2>
              </div>
              <div className="row row-cols-1 row-cols-md-3">
                {alltestimonials.map((Data, index) => {
                  return (
                    <div className="col p-2">
                      <div className="card text-center animated fadeInLeft py-2" key={index}>
                        <h4 style={{ color: "#155588", fontWeight: "bold" }}>{Data.name}</h4>
                        <p>
                          {Data.district}, {Data.state}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default AllCustomerTable;
