import React from "react";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import ServiceBanner from "./Services/ServiceBanner";
// import ServiceList  from './Services/ServiceList'
import ServiceDescription from "./Services/ServiceDescription";
import Page from "./WTTLoading";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
          <Header />
          <ServiceBanner />
          {/* <ServiceList /> */}
          <ServiceDescription />
          <Footer />
        </Page>
      </div>
    );
  }
}

export default App;
