import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import "react-sticky-header/styles.css";
import StickyHeader from "react-sticky-header";
import { Helmet } from "react-helmet";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row1: [],
      row2: [],
      logo: "",
      chatLogo: null,
      chatUrl: null,
      navHide: true,
      metadatas: {},
    };
  }

  componentDidMount() {
    axios.get("/wtt/Header?language=English").then((response) => {
      if (response.data)
        this.setState({
          row1: response.data.data.row1,
          chatLogo: response.data.data.chatlogo.image,
          chatUrl: response.data.data.chatlogo.url,
        });
    });
    axios.get("/wtt/findLogo").then((resp) => {
      this.setState({
        logo: resp.data.data.logoUrl,
      });
    });

    let path = window.location.pathname;
    if (path && path !== "/") {
      path = window.location.pathname.split("/")[1];
    }
    axios.get("/wtt/findParticularMetatag?pagename=" + path).then((resp) => {
      this.setState({
        metadatas: resp.data.data,
      });
    });
  }

  navShowFun = () => {
    this.setState({
      navHide: this.state.navHide ? false : true,
    });
  };

  render() {
    const { metadatas } = this.state;
    const isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function () {
        return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
      },
    };
    return (
      <StickyHeader
        // This is the sticky part of the header.
        header={
          <div>
            {metadatas && (
              <Helmet>
                <meta name="description" content={metadatas.description} />
                <title>{metadatas.title}</title>
                <meta name="keyword" content={metadatas.metakeyword} />
              </Helmet>
            )}
            <header className="overall-header">
              <div className="header-top">
                <div className="container-fluid top-container-fluid">
                  <div className="header-border">
                    <div className="row">
                      <div className="col-md-1 text-left d-flex justify-content-between align-items-center">
                        <NavLink className="navbar-brand" to="/">
                          <img src={this.state.logo} alt="Logo" width={50} />
                        </NavLink>
                        <button className="d-block d-md-none menu-btn" onClick={this.navShowFun}>
                          {this.state.navHide ? <i className="fad fa-bars"></i> : <i className="far fa-times-circle"></i>}
                        </button>
                      </div>
                      <div className="col-md-11 align-self-center text-right">
                        {this.state.row1 ? (
                          <ul className="d-none d-md-block">
                            {this.state.row1.map((Row1Data, index) => {
                              if (Row1Data.url && Row1Data.url.includes("http")) {
                                return (
                                  <li className="nav-item" key={index}>
                                    <a className="nav-link" target="_blank" href={Row1Data.url}>
                                      {Row1Data.title}
                                    </a>
                                  </li>
                                );
                              } else {
                                return (
                                  <li className="nav-item" key={index}>
                                    <NavLink className="nav-link" activeClassName="Activenavline" exact to={Row1Data.url}>
                                      {Row1Data.title}
                                    </NavLink>
                                  </li>
                                );
                              }
                            })}
                            {!isMobile.any() && (
                              <li className="nav-item" style={{ padding: "0.5rem 1rem" }}>
                                {/* <Languages /> */}
                                <GoogleTranslate />
                              </li>
                            )}
                          </ul>
                        ) : null}

                        <ul
                          className="nav-link-con d-md-none"
                          style={{
                            display: this.state.navHide ? "none" : "flex",
                          }}
                        >
                          {this.state?.row1.map((Row1Data, index) => {
                            if (Row1Data.url && Row1Data.url.includes("http")) {
                              return (
                                <li className="nav-item" key={index}>
                                  <a className="nav-link my-2 my-md-0" target="_blank" href={Row1Data.url}>
                                    {Row1Data.title}
                                  </a>
                                </li>
                              );
                            } else {
                              return (
                                <li className="nav-item" key={index}>
                                  <NavLink className="nav-link my-2 my-md-0" activeClassName="Activenavline" exact to={Row1Data.url}>
                                    {Row1Data.title}
                                  </NavLink>
                                </li>
                              );
                            }
                          })}
                          <li className="nav-item" style={{ padding: "0.5rem 1rem" }}>
                            {/* <Languages /> */}
                            <GoogleTranslate />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        }
      />
    );
  }
}

export default Header;
export const GoogleTranslate = () => {
  let duplicate_google_translate_counter = 0; //this stops google adding button multiple times

  const googleTranslateElementInit = () => {
    if (duplicate_google_translate_counter == 0) {
      new window.google.translate.TranslateElement({ pageLanguage: "en", layout: window.google.translate.TranslateElement.FloatPosition.BOTTOM_RIGHT }, "google_translate_element");
    }
    duplicate_google_translate_counter++;
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute("src", "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return <div id="google_translate_element"></div>;
};
