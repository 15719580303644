import React from "react";
import axios from "axios";

class CSRMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title: "",
      content: [],
      errorMessage: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/CSR?language=English").then((response) => {
      if (response.data)
        this.setState({
          content: response.data.data.content,
        });
    });
  }

  render() {
    return (
      <section className="csr">
        <div className="container">
          {this.state.content.map((object, index) => (
            <div className={index === 0 || index === 1 ? "csr-message animatedParent" : "csr-message1 animatedParent"} key={index}>
              <h2 className=" animated fadeInLeft go">{object.title}</h2>
              <div className="row mt-3 mt-md-0">
                <div className={index % 2 ? "col-md-7 order-md-2 align-self-center animated fadeInLeft go" : "col-md-7 order-md-1 align-self-center animated fadeInLeft go"}>
                  <p>{object.text}</p>
                </div>
                <div className={index % 2 ? "col-md-5 order-md-1" : "col-md-5 order-md-2"}>
                  <div className="img-section animated fadeInUp go">
                    <img src={object.image} className="img-fluid" alt="csr" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default CSRMessage;
