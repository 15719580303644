import React from "react";
import axios from "axios";

class ContactBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      background_image: "",
      subheading: "",
      emailid: "",
      emailid_after_text: "",
      button_text: "",
      company_name: "",
      address: "",
      phoneNumber: "",
      timing: "",
      lat: "",
      long: "",
      button_Url: "",
      username: "",
      email: "",
      subject: "",
      organisation: "",
      message: "",
      status: false,
      ConData: {},
    };
  }
  handleData = (e, key) => {
    const temp = this.state.ConData;
    temp[key] = e.target.value;

    this.setState({
      ConData: temp,
    });
  };

  componentDidMount() {
    // if (window.localStorage) {
    //   if (!localStorage.getItem('firstLoad')) {
    //     localStorage['firstLoad'] = true;
    //     window.location.reload();
    //   } else {
    //     localStorage.removeItem('firstLoad');
    //   }
    // }

    axios.get("/wtt/TouchWithUs?language=English").then((response) => {
      if (response.data)
        this.setState({
          title: response.data.data.title,
          background_image: response.data.data.background_image,
          subheading: response.data.data.subheading,
          emailid: response.data.data.emailid,
          emailid_after_text: response.data.data.emailid_after_text,
          button_text: response.data.data.button_text,
          company_name: response.data.data.company_name,
          address: response.data.data.address,
          phoneNumber: response.data.data.phoneNumber,
          timing: response.data.data.timing,
          lat: response.data.data.lat,
          long: response.data.data.long,
          button_Url: response.data.data.button_Url,
        });
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      status: true,
    });

    axios.post("/wtt/touchWithUsMail", this.state.ConData).then(
      (result) => {
        this.setState({
          status: false,
        });
        if (result.data.statusCode === 200) {
          const confirmBox = window.confirm("Thank you, Your Message is sent successfully!");
          if (confirmBox === true) {
            e.target.reset();
          } else {
            e.target.reset();
          }
        } else {
          alert(result.data.message);
        }
      },
      (error) => {
        console.log(error);
        this.setState({
          status: false,
        });
        try {
          if (error) {
            alert(error.response.data.message);
          } else {
            alert("Something Wrong");
          }
        } catch (e) {
          alert("Something Wrong");
        }
      }
    );
  };
  render() {
    const BackgroundImage = this.state.background_image;
    return (
      <div>
        <section className="about-banner hero-banner1 animatedParent mb-5" style={{ background: `url(${BackgroundImage})` }}>
          <div className="pt-4 pb-3">
            <div className="banner-content text-center">
              <h1 className="contact-text">{this.state.title}</h1>
            </div>
          </div>
        </section>

        <section className="contact-us">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div
                className="col-md-6 contact"
                style={{
                  backgroundImage: this.state.background_image,
                  zIndex: "-1",
                }}
              >
                <div className="address">
                  <h3>{this.state.company_name}</h3>
                  <p className="mt-4">{this.state.address}</p>
                  <p>
                    {this.state.phoneNumber} <br />
                    {this.state.timing}
                  </p>
                  <p>
                    <a href="">{this.state.emailid} </a>
                    <br />
                    {this.state.emailid_after_text}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <h4 className="text-center my-3 my-md-5">{this.state.subheading}</h4>
                <form className="pt-0 pb-5 px-5" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="form-group">
                    <input type="text" required onChange={(e) => this.handleData(e, "name")} className="form-control" placeholder="Name" name="name" />
                  </div>
                  <div className="form-group">
                    <input type="email" required onChange={(e) => this.handleData(e, "email")} className="form-control" name="email" placeholder="Email Address" />
                  </div>
                  <div className="form-group">
                    <input type="text" required onChange={(e) => this.handleData(e, "subject")} className="form-control" name="subject" placeholder="Subject" />
                  </div>

                  <div className="form-group">
                    <input type="text" className="form-control" onChange={(e) => this.handleData(e, "designation")} placeholder="Designation" required />
                  </div>
                  <div className="form-group">
                    <input type="number" minLength={10} className="form-control" onChange={(e) => this.handleData(e, "ContactNumber")} placeholder="Contact Number" required />
                  </div>
                  <div className="form-group">
                    <input type="text" required className="form-control" name="organisation" placeholder="Your Organisational Name" onChange={(e) => this.handleData(e, "organizationName")} />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      border: "1px solid #ced4da",
                      borderBottom: "none",
                    }}
                  >
                    <textarea className="form-control" onChange={(e) => this.handleData(e, "message")} required name="message" rows="3" placeholder="Present Requirement"></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary" disabled={this.state.status}>
                    {this.state.status ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      this.state.button_text
                    )}
                  </button>
                </form>
              </div>

              {/* <div className="col-md-12 py-5 bg-dark"></div> */}
            </div>

            <div className="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.0020832236232!2d77.32683451411899!3d11.113222155927113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba9070128d828b7%3A0x9d3de0f0ff1785f!2sW.t.t%20technology%20Services%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1623317321043!5m2!1sen!2sin" className="map" style={{ border: "0" }} allowFullScreen="" loading="lazy"></iframe>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ContactBanner;
