import React from "react";
import axios from "axios";

class QAs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alltestimonials: [],
      CardIndex: 0,
    };
  }
  componentDidMount() {
    axios.get("/wtt/findAllCetpCustomerByLanguage?language=English").then((response) => {
      if (response.data)
        this.setState({
          alltestimonials: response.data.data,
        });
    });
  }
  render() {
    return (
      <section className="customer testimonial animatedParent">
        {this.state.alltestimonials.length ? (
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="news-title animated fadeInLeft">
                  <h2 className="animated fadeInLeft">Our CETP Customers</h2>
                </div>
              </div>
              <div className="col-md-6 align-self-center text-right">
                <a href="/AllCustomerTable">See All</a>
              </div>
            </div>
            <div className="row">
              {this.state.alltestimonials.slice(this.state.CardIndex, this.state.CardIndex + 6).map((Data, index) => {
                return (
                  <div className="col-md-4 my-3" key={index}>
                    <div className="card text-center animated fadeInLeft py-2" key={index}>
                      <h4 style={{ color: "#155588", fontWeight: "bold" }}>{Data.name}</h4>
                      <p>
                        {Data.district}, {Data.state}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-between mt-3">
              {this.state.CardIndex !== 0 ? (
                <div className="text-left next">
                  <span
                    onClick={() => {
                      if (this.state.CardIndex !== 0) {
                        this.setState({ CardIndex: this.state.CardIndex - 6 });
                      }
                    }}
                    style={{ fontWeight: 600 }}
                  >
                    <i className="pl-2 far fa-arrow-left mr-2"></i>Prev
                  </span>
                </div>
              ) : null}
              {this.state.CardIndex + 6 < this.state.alltestimonials.length && (
                <div className="text-right next">
                  <span
                    onClick={() => {
                      if (this.state.CardIndex + 6 < this.state.alltestimonials.length) {
                        this.setState({ CardIndex: this.state.CardIndex + 6 });
                      }
                    }}
                    style={{ fontWeight: 600 }}
                  >
                    Next<i className="pl-2 far fa-arrow-right"></i>
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default QAs;
