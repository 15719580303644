import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";
import CountUp from "react-countup";
class HomeTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title1_sidebox: [],
      sub_title1_content: [],
      sub_title1_background_image: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/HomePage?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title1_sidebox: response.data.data.sub_title1_sidebox,
          sub_title1_content: response.data.data.sub_title1_content,
          sub_title1_background_image: response.data.data.sub_title1_background_image,
        });
    });
  }
  render() {
    return (
      <section className="no-of-clients animatedParent">
        <div className="container-fluid p-0 animated fadeInRight">
          <div className="row no-gutters">
            <div
              className="col-lg-9 col-sm-6 no-clients"
              style={{
                background: `url(${this.state.sub_title1_background_image})`,
              }}
            >
              <div className="row" style={{ overflow: "hidden", margin: "0px" }}>
                <div className="col-lg-8 col-sm-0"></div>

                <div className="col-lg-4 col-sm-12">
                  {this.state.sub_title1_content.length ? (
                    <div className="row py-3">
                      {this.state.sub_title1_content.map((Data, index) => {
                        return (
                          <Zoom key={index}>
                            <div key={index} className="col-md-12">
                              <div className={"branches" + (index + 1)}>
                                <h1>
                                  <CountUp enableScrollSpy end={Data.count} duration={1.25} />
                                </h1>

                                <h3>{Data.title}</h3>
                              </div>
                            </div>
                          </Zoom>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {this.state.sub_title1_sidebox.length ? (
              <div className="col-lg-3 col-sm-6">
                <div className="row no-gutters">
                  {this.state.sub_title1_sidebox.map((Data, index) => {
                    return (
                      <Zoom key={index}>
                        <div className={index === 0 ? "col-md-12 pt-md-5" : "col-md-12 pb-md-5"} style={{ background: "#effaff" }}>
                          <div className="branches">
                            <h1>{Data.count.includes("+") ? Data.count : <CountUp enableScrollSpy end={Data.count} duration={1.25} />}</h1>

                            <h3>{Data.title}</h3>
                          </div>
                        </div>
                      </Zoom>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

export default HomeTest;

export function CountUpHook() {
  return <div>HomeTest</div>;
}
