import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import NewsBanner from "./News/NewsBanner";
import NewsDescription from "./News/NewsDescription";
import Page from "./WTTLoading";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
          <Header />
          <NewsBanner />
          <NewsDescription />
          <Footer />
        </Page>
      </div>
    );
  }
}

export default App;
