import React from "react";

// project imports

import * as serviceWorker from "./serviceWorker";
import App from "./App";

// style + assets
import "./assets/scss/style.scss";

//-----------------------|| REACT DOM RENDER  ||-----------------------//

// Re actDOM.render(
//     <Provider store={store}>
//         <PersistGate loading={null} persistor={persister}>
//             <BrowserRouter basename={config.basename}>
//                 <App />
//             </BrowserRouter>
//         </PersistGate>
//     </Provider>,
//     document.getElementById('root')
// );

export class AdminIndex extends React.Component {
  render() {
    return <App />;
  }
}

export default AdminIndex;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
