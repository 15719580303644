import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

class HomeNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title2_sub_heading: "",
      sub_title2_content: [],
      sub_title2: "",
      sub_title2_url: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/HomePage?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title2: response.data.data.sub_title2,
          sub_title2_url: response.data.data.sub_title2_url,
          sub_title2_sub_heading: response.data.data.sub_title2_sub_heading,
          sub_title2_content: response.data.data.sub_title2_content,
        });
    });
  }
  render() {
    return (
      <section className="testimonial-news animatedParent">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="news-title animated fadeInLeft">
                <h2 style={{ letterSpacing: "-2px", fontWeight: "600" }}>{this.state.sub_title2}</h2>
                <p>{this.state.sub_title2_sub_heading}</p>
              </div>
            </div>
            <div className="col-md-6 align-self-center text-right">
              <a href="/news">See All</a>
            </div>
          </div>

          {this.state.sub_title2_content.length ? (
            <div className="row py-md-5 animated fadeInLeft">
              {this.state.sub_title2_content.map((Data, index) => {
                return (
                  <Zoom key={index}>
                    <div key={index} className="col-md-4 my-3 my-md-0">
                      <div className="card">
                        <img src={Data.image} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <p className="card-text">{Data.text}</p>
                          <a className="btn btn-primary" href={Data.url}>
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                );
              })}
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

export default HomeNews;
