import React, { Component, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./Home/home";
import Explore from "./explore";
import Contact from "./contact";
import Products from "./products";
import Services from "./services";
import Industries from "./industries";
import Process from "./process";
import Resources from "./resources";
import Gallery from "./gallery";
import Career from "./career";
import Global from "./global";
import CSR from "./csr";
import CSRProjectDetails from "./CSR/CSRProjectDetails";
import News from "./news";
import Research from "./research";
import Error from "./Error";
import AdminIndex from "./Admin";
import { store, persister } from "./Admin/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import config from "./Admin/config";
import Awards from "./Awards";
import AllCustomerTable from "./AllCustomerTable";
import AllClients from "./AllClients";
import PerfectScrollbar from "react-perfect-scrollbar";

class App extends Component {
  render() {
    return (
      // <PerfectScrollbar>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/Admin" component={AdminIndex} />
        <Route path="/explore" component={Explore} />
        <Route path="/contact" component={Contact} />
        <Route path="/products" component={Products} />
        <Route path="/services" component={Services} />
        <Route path="/industries" component={Industries} />
        <Route path="/process" component={Process} />
        <Route path="/resources" component={Resources} />
        <Route path="/global" component={Global} />
        <Route path="/csr" component={CSR} />
        <Route path="/csrprojectdetails/:sub_title_type/:content" component={CSRProjectDetails} />
        <Route path="/news" component={News} />
        <Route path="/research" component={Research} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/career" component={Career} />
        <Route path="/Awards" component={Awards} />
        <Route path="/AllCustomerTable" component={AllCustomerTable} />
        <Route path="/AllClients" component={AllClients} />
        <Route component={Error} />
      </Switch>

      // </PerfectScrollbar>
    );
  }
}

export default App;
