import axios from "axios";
import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      muted: {},
    };
  }
  componentDidMount() {
    axios.get("/wtt/HomePage?language=English").then((response) => {
      if (response.data)
        this.setState({
          videos: response.data.data.Videos,
        });
    });
  }

  render() {
    const options = {
      loop: false,
      nav: true,
      navText: ["<i className='fas fa-chevron-left'></i> ", "<i className='fas fa-chevron-right'></i>"],
      dots: false,
      autoplay: false,
      margin: 30,
      autoplayTimeout: 4500,
      autoplaySpeed: 1000,
      lazyLoad: true,
      singleitem: "true",
      items: 1,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 1,
        },
        1100: {
          items: 1,
        },
      },
    };

    return (
      <section className="hero-banner" style={{ background: "#e2ecf6", margin: "0px", padding: "0" }}>
        {this.state.videos.length ? (
          <div>
            <div className="container-fluid p-0">
              <OwlCarousel className="owl-theme" {...options}>
                {/* {DummyLoop} */}
                {this.state.videos.map((data, index) => (
                  <Tempvideoplayer key={index} data={data} />
                ))}
              </OwlCarousel>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default HomeBanner;

const Tempvideoplayer = ({ data }) => {
  const [muted, setMuted] = useState(true);
  const handleToggleMute = () => {
    setMuted(!muted);
  };
  return (
    <div>
      <div className="video-slider">
        <video muted={muted} playsInline autoPlay loop>
          <source src={data} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <button onClick={() => handleToggleMute()} className="ControlMute">
        {muted ? <i className="fas fa-volume-mute"></i> : <i className="fas fa-volume-up"></i>}
      </button>
    </div>
  );
};