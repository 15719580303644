import React from "react";
import axios from "axios";
import Slide from 'react-reveal/Slide';
class ServiceBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      text: "",
      sub_title: "",
      sub_title_text: "",
      image: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/Services?language=English").then((response) => {
      if (response.data)
        this.setState({
          title: response.data.data.title,
          text: response.data.data.text,
          sub_title: response.data.data.sub_title,
          sub_title_text: response.data.data.sub_title_text,
          image: response.data.data.image,
        });
    });
  }

  render() {
    return (
      <section className="banner service-banner1">
        <div className="container-fluid p-0">
          <div>
            <div className="row no-gutters">
              <div className="col-md-6 pb-3">
                <div className="img-banner">
                  <img alt="WTT..." src={this.state.image} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6 align-self-end">
                <Slide>
                  <div className="service-banner">
                    <h1>{this.state.title}</h1>
                    <p>{this.state.text}</p>
                    <h6>{this.state.sub_title}</h6>
                    <p>{this.state.sub_title_text}</p>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ServiceBanner;
