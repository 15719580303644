import React from "react";
import axios from "axios";

class GalleryImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image_data: [],
      errorMessage: "",
    };
  }
  componentDidMount() {
    // if (window.localStorage) {
    //   if (!localStorage.getItem('firstLoad')) {
    //     localStorage['firstLoad'] = true;
    //     window.location.reload();
    //   } else {
    //     localStorage.removeItem('firstLoad');
    //   }
    // }

    axios.get("/wtt/findParticularCareer?language=English").then((response) => {
      if (response.data)
        this.setState({
          image_data: response.data.data.img,
        });
    });
  }

  render() {
    return (
      <section className="career">
        <div className="container mt-5"></div>
      </section>
    );
  }
}

export default GalleryImages;
