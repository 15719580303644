import React from "react";
import Zoom from "react-reveal/Zoom";
import axios from "axios";

class ServiceDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
    };
  }
  componentDidMount() {
    axios.get("/wtt/Services?language=English").then((response) => {
      if (response.data)
        this.setState({
          content: response.data.data.content,
        });
    });
  }

  render() {
    return (
      <section className="service-name py-md-5">
        {this.state.content.length ? (
          <div className="container">
            {this.state.content.map((Data, index) => {
              return (
                <div className="row mb-4" key={index}>
                  <div className={index % 2 ? "col-md-7 align-self-center order-md-2" : "col-md-7 align-self-center"}>
                    <h2>{Data.title}</h2>
                    <p>{Data.text}</p>
                  </div>
                  <div className={index % 2 ? "col-md-5 align-self-center order-md-1" : "col-md-5 align-self-center"}>
                    <Zoom>
                      <div className="img-section">
                        <img alt="WTT..." src={Data.image} className="img-fluid" />
                      </div>
                    </Zoom>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </section>
    );
  }
}

export default ServiceDescription;
