import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import $ from "jquery";
const P$ = $;

class ResearchDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title: "",
      content: [],
      indexValue: 0,
      status: true,
    };
    this.scroll = this.scroll.bind(this);
  }

  scroll(direction) {
    let far = (P$(".image-container").width() / 2) * direction;
    let pos = P$(".image-container").scrollLeft() + far;
    P$(".image-container").animate({ scrollLeft: pos }, 1000);
  }
  componentDidMount() {
    axios.get("/wtt/RD?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title: response.data.data.sub_title,
          content: response.data.data.content,
        });
    });
  }
  handleIndexValue = (i) => {
    this.setState({
      indexValue: i,
      status: !this.state.status,
    });
  };

  render() {
    const options = {
      nav: true,
      navText: [, "<i className='far fa-arrow-right'></i>"],
      autoplay: true,
      dots: false,
      loop: true,
      margin: 10,
      autoplayTimeout: 4500,
      autoplaySpeed: 1000,
      lazyLoad: true,
      singleitem: "true",
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 2,
        },
        1100: {
          items: 3,
        },
      },
    };
    var AssembledData = [];
    for (let index = 0; index < this.state.content.length; index++) {
      const element = (
        <div id="headingOne">
          <a
            data-toggle="collapse"
            data-target={"#collapse" + index}
            aria-expanded="true"
            aria-controls={"collapse" + index}
            onClick={() => {
              this.handleIndexValue(index);
            }}
          >
            <img alt="WTT..." src={this.state.content[index].image} className="img-fluid" />
            <h6>{this.state.content[index].title}</h6>
          </a>
        </div>
      );
      AssembledData.push(element);
    }
    return (
      <section className="research">
        {this.state.content.length ? (
          <div className="container">
            <h1 className="text-center">{this.state.sub_title}</h1>

            <div className="accordion" id="accordionExample">
              <OwlCarousel className="owl-theme" {...options}>
                {AssembledData}
              </OwlCarousel>

              <div className={this.state.status ? "card text-center animated fadeInUp go" : "card text-center animated fadeInDown go"}>
                <div className="row justify-content-center pb-5">
                  <div className="col-md-4">
                    <img alt="WTT..." src={this.state.content[this.state.indexValue].image} className="img-fluid" />
                  </div>
                </div>
                <h3>{this.state.content[this.state.indexValue].title}</h3>
                <p>{this.state.content[this.state.indexValue].text}</p>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default ResearchDescription;
