import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

class GlobalProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title: "",
      sub_title_content: [],
      sub_title_image: "",
      sub_title_text: "",
      sub_title1: "",
      sub_title1_text: "",
      errorMessage: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/GlobalReferences?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title: response.data.data.sub_title,
          sub_title_image: response.data.data.sub_title_image,
          sub_title_text: response.data.data.sub_title_text,
          sub_title_content: response.data.data.sub_title_content,
          sub_title1: response.data.data.sub_title1,
          sub_title1_text: response.data.data.sub_title1_text,
        });
    });
  }
  render() {
    return (
      <section className="global-reference">
        <div className="container">
          <div className="row animatedParent">
            <div className="col-md-8 mb-5">
              <h3 className=" animated fadeInLeft">{this.state.sub_title}</h3>
              <h6 className=" animated fadeInLeft">{this.state.sub_title_text}</h6>
              <img alt="WTT..." src={this.state.sub_title_image} className=" animated fadeIn mt-4 mt-md-5 img-fluid" />
            </div>
            <div className="col-md-4">
              {this.state.sub_title_content.map((object, index) => (
                <div className="card  animatedParent" key={index}>
                  <img src={object.image} className="animated fadeIn card-img-top" alt="..." />
                  <h5 className="card-title  animated fadeInRight">{object.image_text}</h5>

                  <div className="card-body">
                    <p className="card-text">{object.text}</p>
                    <a href={object.url} target="_blank" className="btn btn-primary ">
                      Explore project
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="animatedParent">
            <h4 className="animated fadeInUp">{this.state.sub_title1}</h4>
            <p className="pl-md-5 animated fadeInUp">{this.state.sub_title1_text}</p>
          </div>
        </div>
      </section>
    );
  }
}
export default GlobalProject;
