import React from "react";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import ProcessBanner from "./Process/ProcessBanner";
import ProcessList from "./Process/ProcessList";
import Page from "./WTTLoading";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
          <Header />
          <br />
          <br />
          <br /> <br />
          <ProcessBanner />
          <ProcessList />
          <Footer />
        </Page>
      </div>
    );
  }
}

export default App;
