// assets
import { IconBrandChrome, IconHelp, IconSitemap } from "@tabler/icons";

// constant
const icons = {
  IconBrandChrome: IconBrandChrome,
  IconHelp: IconHelp,
  IconSitemap: IconSitemap,
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const other = {
  id: "sample-docs-roadmap",
  type: "group",
  children: [
    {
      id: "Home",
      title: "Home",
      type: "item",
      url: "/Admin/Home",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },

    { id: "WorldwidePresence", title: "Worldwide Presence", type: "item", url: "/Admin/WorldwidePresence", icon: icons["IconBrandChrome"], breadcrumbs: false },
    { id: "Logo", title: "Logo", type: "item", url: "/Admin/Logo", icon: icons["IconBrandChrome"], breadcrumbs: false },
    { id: "Clientst", title: "Clients", type: "item", url: "/Admin/ClientImage", icon: icons["IconBrandChrome"], breadcrumbs: false },
    { id: "CustomerT", title: "Customer Table", type: "item", url: "/Admin/CustomerTableIndex", icon: icons["IconBrandChrome"], breadcrumbs: false },
    { id: "QAS", title: "QAs", type: "item", url: "/Admin/QAsIndex", icon: icons["IconBrandChrome"], breadcrumbs: false },
    {
      id: "Admins",
      title: "Admins",
      type: "item",
      url: "/Admin/Admins",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },{
      id: "Brochure",
      title: "Brochure",
      type: "item",
      url: "/Admin/Brochure",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    
    {
      id: "Industries-We-Serve",
      title: "Industries We Serve",
      type: "item",
      url: "/Admin/IndustriesWeServe",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Solutions",
      title: "Solutions",
      type: "item",
      url: "/Admin/Solutions",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Resources",
      title: "Resources",
      type: "item",
      url: "/Admin/Resources",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },

    {
      id: "Gallery",
      title: "Gallery",
      type: "item",
      url: "/Admin/Gallery",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Career",
      title: "Career",
      type: "item",
      url: "/Admin/Career",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Global-References",
      title: "Global References",
      type: "item",
      url: "/Admin/GlobalReferences",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "CSR",
      title: "CSR",
      type: "item",
      url: "/Admin/CSR",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "News-Media",
      title: "News & Media",
      type: "item",
      url: "/Admin/NewsMedia",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "R&D",
      title: "R & D",
      type: "item",
      url: "/Admin/RD",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Languages",
      title: "Languages",
      type: "item",
      url: "/Admin/Languages",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "ExploreWTT",
      title: "About Us",
      type: "item",
      url: "/Admin/ExploreWTT",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Products",
      title: "Products",
      type: "item",
      url: "/Admin/Products",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Services",
      title: "Services",
      type: "item",
      url: "/Admin/Services",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "TouchWithUs",
      title: "Touch With Us",
      type: "item",
      url: "/Admin/TouchWithUs",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Header",
      title: "Header",
      type: "item",
      url: "/Admin/Header",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Awards",
      title: "Awards & Recognition",
      type: "item",
      url: "/Admin/AwardsIndex",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "Footer",
      title: "Footer",
      type: "item",
      url: "/Admin/Footer",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
    {
      id: "SEO",
      title: "SEO Tags",
      type: "item",
      url: "/Admin/SEOTags",
      icon: icons["IconBrandChrome"],
      breadcrumbs: false,
    },
  ],
};
// {
//   id: "Testimonials",
//   title: "Testimonials",
//   type: "item",
//   url: "/Admin/Testimonials",
//   icon: icons["IconBrandChrome"],
//   breadcrumbs: false,
// },