import React from "react";
import axios from "axios";
class NewsBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      background_image: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/NewsMedia?language=English").then((response) => {
      if (response.data)
        this.setState({
          title: response.data.data.title,
          background_image: response.data.data.background_image,
        });
    });
  }

  render() {
    const BackgroundImage = this.state.background_image;
    return (
      <section className="hero-banner1" style={{ background: `url(${BackgroundImage})` }}>
        <div className="container">
          <div className="pt-5">
            <div className="row pt-md-5 mt-md-5">
              <div className="col-md-6 py-5 pb-3">
                <div className="global-content1">
                  <h1>{this.state.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NewsBanner;
