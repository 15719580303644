import React from "react";
import axios from "axios";

class HomeClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title3: "",
      sub_title3_content: [],
      indexValue: 0,
    };
  }
  componentDidMount() {
    axios.get("/wtt/HomePage?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title3: response.data.data.sub_title3,
          sub_title3_content: response.data.data.sub_title3_content,
        });
    });
  }
  handleIndex = () => {
    if (this.state.sub_title3_content.length - 1 <= this.state.indexValue) {
      this.setState({
        indexValue: 0,
      });
    } else {
      this.setState({
        indexValue: this.state.indexValue + 1,
      });
    }
  };

  render() {
    return (
      <section className="testimonial animatedParent">
        <div className="container">
          <h2 className="animated fadeInLeft">{this.state.sub_title3}</h2>

          {this.state.sub_title3_content.length ? (
            <div className="row">
              <div className="col-lg-6 col-sm-12 mb-5">
                <p>{this.state.sub_title3_content[this.state.indexValue].text}</p>
                <div className="sec-footer">
                  <h6>
                    {this.state.sub_title3_content[this.state.indexValue].name}
                    <br />
                    {this.state.sub_title3_content[this.state.indexValue].post}
                  </h6>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="gallery">
                  <div className="gallery-container">
                    <img className="gallery-item gallery-item-3" src={this.state.sub_title3_content[this.state.indexValue].image} data-index="1" />
                    <img className="gallery-item gallery-item-4" src={this.state.sub_title3_content.length === this.state.indexValue + 1 ? this.state.sub_title3_content[0].image : this.state.sub_title3_content[this.state.indexValue + 1].image} data-index="2" />
                  </div>
                  <div className="gallery-controls" onClick={() => this.handleIndex()}>
                    <i className="fas fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

export default HomeClients;
