import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
export default function PageLoading({ duration, children, ...props }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const containerStyle = {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  if (loading) {
    return (
      <div style={containerStyle}>
        <ReactLoading {...props} type={"spin"} />
      </div>
    );
  }

  return <div>{children}</div>;
}
