import React from "react";
import axios from "axios";
import { withRouter } from "react-router";
class CSRProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title: "",
      sub_title_content: [],
      errorMessage: "",
      indexValue: 0,
      ProjectIndex: 0,
      VideoIndex: 0,
    };
  }
  componentDidMount() {
    axios.get("/wtt/CSR?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title: response.data.data.sub_title,
          sub_title_content: response.data.data.sub_title_type,
        });
    });
  }
  handleIndex = (i) => {
    this.setState({
      indexValue: i,
    });
  };

  render() {
    return (
      <section className="csr-products animatedParent">
        {this.state.sub_title_content.length ? (
          <div className="container">
            <h1 className="text-center animated fadeInLeft">{this.state.sub_title}</h1>
            <div className="accordion py-md-5" id="accordionExample">
              <div className="row text-center no-gutters justify-content-center">
                {this.state.sub_title_content.map((Title, index) => {
                  return (
                    <div className="col-md-4 col-6 mb-3" key={index}>
                      <div className="card">
                        <div className="card-header" id="heading1">
                          <h6 className="mb-0">
                            <a className={this.state.indexValue === index ? "product-button active" : "product-button"} onClick={() => this.handleIndex(index)} type="button">
                              {Title.title}
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row py-5">
                <div className="col-md-12">
                  <div>
                    <div className="row">
                      {this.state.sub_title_content[this.state.indexValue]["content"].slice(this.state.ProjectIndex, this.state.ProjectIndex + 3).map((Data, index) => {
                        return (
                          <div className="col-md-4 px-4 pb-5 mb-4" key={index}>
                            <div className={index % 2 ? "card animated fadeInLeft go" : "card animated fadeInRight go"}>
                              <img src={Data.image} className="card-img-top" alt="..." />
                              <div className="card-title">
                                <h5>{Data.title}</h5>
                                <p className="card-text">{Data.text}</p>
                                <button
                                  onClick={() => {
                                    this.props.history.push(`/csrprojectdetails/${this.state.indexValue}/${index}`);
                                  }}
                                  className="btn btn-primary"
                                >
                                  Read More
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-between">
                      {this.state.ProjectIndex !== 0 ? (
                        <div className="text-left next mt-md-5">
                          <span
                            onClick={() => {
                              if (this.state.ProjectIndex !== 0) {
                                this.setState({
                                  ProjectIndex: this.state.ProjectIndex - 3,
                                });
                              }
                            }}
                            style={{ fontWeight: 600 }}
                          >
                            <i className="pl-2 far fa-arrow-left"></i>Prev
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {this.state.ProjectIndex + 3 < this.state.sub_title_content[this.state.indexValue]["content"].length && (
                        <div className="text-right next mt-md-5">
                          <span
                            onClick={() => {
                              if (this.state.ProjectIndex + 3 < this.state.sub_title_content[this.state.indexValue]["content"].length) {
                                this.setState({
                                  ProjectIndex: this.state.ProjectIndex + 3,
                                });
                              }
                            }}
                            style={{ fontWeight: 600 }}
                          >
                            Next<i className="pl-2 far fa-arrow-right"></i>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default withRouter(CSRProject);
