import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

class WhoWe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      bcakgroundImage: "",
      content: "",
      text: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/findParticularTeams?language=English").then((response) => {
      if (response.data) {
        this.setState({
          title: response.data.data.title,
          bcakgroundImage: response.data.data.bcakgroundImage,
          content: response.data.data.content,
          text: response.data.data.text,
        });
      }
    });
  }

  render() {
    return (
      <section className="about-banner mb-5" style={{ background: "url(" + this.state.bcakgroundImage + ")" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="banner-text banner-content text-center animatedParent">
                <Zoom>
                  <h1 className="animated">{this.state.title}</h1>
                  <p className="animated mt-2 mt-md-4">
                    {this.state.content}
                    {/* WTT Technology Services India Pvt Ltd having Head Office in Tirupur, Tamilnadu headed by the Managing Director
                  of the company Mr.D.Venkatesh & Director of the company Mrs.V.Priya, Head office is equipped with Project
                  execution, Technical and Process competent Engineers of enough in quantity where the Project execution and
                  Process stabilisation of the plants across country has been dealt with. Installation, Errection and
                  Commissioning of the Plants has been done by the competent engineers in a Techno economical way yielding the
                  best results of the projects.
                  <br />
                  <br /> WTTs leadership can be seen in Innovation, Research & Development, Technology Upgradation & low cost
                  running of plants. <br />
                  Today WTT is also in the field of undertaking O & M & Technology Upgradation of any kind of plant in its nature
                  where there is a real challenge in meeting ZLD. */}
                  </p>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid p-0 animatedParent">
          <img alt="WTT..." src={this.state.image} className=" animated fadeLeft mt-5 img-fluid" />
        </div> */}
      </section>
    );
  }
}

export default WhoWe;
