import React from 'react';
import axios from 'axios';

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Clients',
      content: [],
      indexValue: 0,
    };
  }
  componentDidMount() {
    axios
      .get('/wtt/findAllClientImage')
      .then((response) => {
        if (response.data)
          this.setState({
            content: response.data.data,
          });
      });
  }

  render() {
    let { title, content } = this.state;

    return (
      <section className='animatedParent'>
        {content.length ? (
          <div className='container'>
            <div className='py-5 mt-5'>
              <div className='news-title animated fadeInLeft my-5'>
                <h2 className='animated fadeInLeft'>{title}</h2>
              </div>
              <div className='row row-cols-1 row-cols-md-4'>
                {content.map((item, index) => {
                  return (
                    <div className='col p-2'>
                      <center
                        className='card animated fadeInLeft py-2'
                        key={index}>
                        <img
                          style={{}}
                          alt={item.title}
                          className='img-fluid'
                          key={index}
                          src={item.image}
                        />
                      </center>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default Clients;
