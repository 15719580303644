import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

class ProductBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      text: "",
      image: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/Products?language=English").then((response) => {
      if (response.data)
        this.setState({
          title: response.data.data.title,
          image: response.data.data.image,
          text: response.data.data.text,
        });
    });
  }

  render() {
    return (
      <section className="about-banner hero-banner1 animatedParent mb-5" style={{ background: "url(" + this.state.image + ")" }}>
        <div className="pt-4 pb-3">
          <div className="banner-content text-center">
            <Zoom>
              <h1>{this.state.title}</h1>
              <p>{this.state.text}</p>
            </Zoom>
          </div>
        </div>
      </section>
    );
  }
}

export default ProductBanner;
