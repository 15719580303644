import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
class IndustriesBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalReactPackages: [],
      errorMessage: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/IndustriesWeServe?language=English").then((response) => {
      if (response.data)
        this.setState({
          totalReactPackages: response.data.data.content,
          background_image: response.data.data.background_image,
        });
    });
  }
  handleIndexValue = (i) => {
    this.setState({
      indexValue: i,
    });
  };
  render() {
    const options = {
      nav: true,
      autoplay: true,
      dots: false,
      loop: true,
      margin: 30,
      items: 1,
      autoplayTimeout: 4500,
      autoplaySpeed: 1000,
      lazyLoad: true,
      singleitem: "true",
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 1,
        },
        1100: {
          items: 1,
        },
      },
    };
    return (
      <section
        className="hero-banner1 testimonial"
        style={{
          background: "url(" + this.state.background_image + ")",
          height: "auto",
        }}
      >
        {this.state.totalReactPackages.length ? (
          <div className="container">
            <div className="pt-md-5">
              <div className="row pt-md-5">
                <div className="col-md-6"></div>
                <div className="col-md-6 pt-md-5 pb-3">
                  <Zoom>
                    <div className="industries">
                      <OwlCarousel autoplayHoverPause={true} className="owl-theme" {...options}>
                        {this.state.totalReactPackages.map((object, index) => (
                          <div className="item" key={index}>
                            <h1>{object.title}</h1>
                            <p>{object.text}</p>
                            {/* <a href="#">Read More</a> */}
                          </div>
                        ))}
                      </OwlCarousel>
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default IndustriesBanner;
