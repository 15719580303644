import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Page from "../WTTLoading"
import AllCustomerTable from "./ClientsList";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
          <Header />
          <AllCustomerTable />
          <Footer />
        </Page>
      </div>
    );
  }
}

export default App;
