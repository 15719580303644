import React from "react";
import axios from "axios";
import { decode } from "html-entities";
class Values extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Values: "",
    };
  }

  componentDidMount() {
    axios.get("/wtt/findParticularTeams?language=English").then((response) => {
      if (response.data) {
        this.setState({
          Values: response.data.data.values,
        });
      }
    });
  }

  render() {
    return (
      <div className="history animatedParent my-5">
        <div className="container">
          <h2 className="text-left animated fadeInLeft">Values</h2>
          {this.state.Values ? (
            <div
              className="animated fadeInLeft"
              dangerouslySetInnerHTML={{
                __html: `${decode(this.state.Values)}`,
              }}
            ></div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Values;
