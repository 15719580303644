import React from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Features: [],
      page_link: [],
      company_name: "",
      address: "",
      email: "",
      logo: "",
      logo_after_text: "",
      fbUrl: "",
      linkedinUrl: "",
      phone: "",
      show: false,
      youtubedata: {},
      whatsappdata: {},
      instadata: {},
      twitterdata: {},
      username: "",
      email: "",
      subject: "",
      organisation: "",
      message: "",
      status: false,
      emailId: "",
      ConData: {},
      download_broucher: "",
    };
  }
  getYear() {
    return new Date().getFullYear();
  }
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleUsername = (e) => {
    this.setState({
      username: e.target.value,
    });
  };
  handleEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  handleSubject = (e) => {
    this.setState({
      subject: e.target.value,
    });
  };
  handleOrganisation = (e) => {
    this.setState({
      organisation: e.target.value,
    });
  };
  handleMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  componentDidMount() {
    axios.get("/wtt/Footer?language=English").then((response) => {
      this.DownloadBrocher();
      if (response.data)
        this.setState({
          Features: response.data.data.Features,
          page_link: response.data.data.pagelink,
          company_name: response.data.data.company_name,
          address: response.data.data.address,
          email: response.data.data.email,
          logo_after_text: response.data.data.logo_after_text,
          fbUrl: response.data.data.fbUrl,
          linkedinUrl: response.data.data.linkedinUrl,
          phone: response.data.data.phone,
        });
      if (response.data.data.youtubedata) {
        this.setState({ youtubedata: response.data.data.youtubedata });
      }
      if (response.data.data.whatsappdata) {
        this.setState({ whatsappdata: response.data.data.whatsappdata });
      }
      if (response.data.data.twitterdata) {
        this.setState({ twitterdata: response.data.data.twitterdata });
      }
      if (response.data.data.instadata) {
        this.setState({ instadata: response.data.data.instadata });
      }
    });
    axios.get("/wtt/findLogo").then((resp) => {
      this.setState({
        logo: resp.data.data.logoUrl,
      });
    });
  }
  download = async (pdfUrl) => {
    await axios({
      method: "get",
      url: pdfUrl,
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `WTT-Brochure.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        return error;
      });
  };
  DownloadBrocher = () => {
    axios.get("/wtt/findFirstBroucher").then(async (response) => {
      if (response.data) {
        const urlLink = response.data.data.download_broucher;
        this.setState({ download_broucher: urlLink });
      }
    });
  };
  handleData = (e, key) => {
    const temp = this.state.ConData;
    temp[key] = e.target.value;

    this.setState({
      ConData: temp,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    axios.post("/wtt/touchWithUsMail", this.state.ConData).then(
      (result) => {
        this.setState({
          status: false,
        });
        if (result.data.statusCode === 200) {
          this.setState({ show: false });
          const confirmBox = window.confirm("Thank you, Your Message is sent successfully!");
          if (confirmBox === true) {
            e.target.reset();
          } else {
            e.target.reset();
          }
        } else {
          alert(result.data.message);
        }
      },
      (error) => {
        console.log(error);
        this.setState({
          status: false,
        });
        try {
          if (error) {
            alert(error.response.data.message);
          } else {
            alert("Something Wrong");
          }
        } catch (e) {
          alert("Something Wrong");
        }
      }
    );
  };

  handleSubmitEmail = (e) => {
    e.preventDefault();
    const Data = {
      email: this.state.emailId,
    };
    axios.post("/wtt/mailsend", Data).then(
      (result) => {
        this.setState({
          status: false,
        });
        if (result.data.statusCode === 200) {
          this.setState({ show: false });
          const confirmBox = window.confirm(result.data.message);
          if (confirmBox === true) {
            e.target.reset();
          } else {
            e.target.reset();
          }
        } else {
          alert(result.data.message);
        }
      },
      (error) => {
        console.log(error);
        this.setState({
          status: false,
        });
        try {
          if (error) {
            alert(error.response.data.message);
          } else {
            alert("Something Wrong");
          }
        } catch (e) {
          alert("Something Wrong");
        }
      }
    );
  };

  render() {
    return (
      <div className="footer">
        <div className="sticky-call">
          <ReactTooltip />
          <ul>
            <li data-tip="New-Plant-Enquiries">
              <a onClick={() => window.open("http://bit.ly/WTT-NewPlant", "_blank")}>
                {/* <img alt="Enquiry Form" src="assets/images/FormImage.png" className="img-fluid" /> */}
                <i className="fas fa-file"></i>
              </a>
            </li>
            <li data-tip="Contact Us">
              <a data-toggle="modal" data-target="#exampleModal10">
                {/* <img alt="Enquiry Form" src="assets/images/FormImage.png" className="img-fluid" /> */}
                <i className="fas fa-envelope"></i>
              </a>
            </li>

            <li data-tip="Download Brochure">
              <a data-toggle="modal" data-target="#exampleModal11">
                <i className="fas fa-download"></i>
              </a>
            </li>
            <li data-tip="Email Handbook">
              <a data-toggle="modal" data-target="#exampleModal12">
                <i className="fas fa-paper-plane"></i>
              </a>
            </li>
            {this.state.whatsappdata && this.state.whatsappdata.status && (
              <li data-tip="Whatsapp">
                <a href={`https://wa.me/${this.state.whatsappdata.mobileNumber}`} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="modal fade" id="exampleModal11" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="content">
                <h5>Download the brochure</h5>

                <button
                  onClick={() => {
                    this.download(this.state.download_broucher);
                  }}
                  className="btn btn-primary"
                >
                  Download
                </button>
              </div>
              <div className="text-right">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <img alt="WTT..." src="assets/images/close.png" className="img-fluid" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal10" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="content text-center">
                <h5>Enter the below details</h5>
                <form onSubmit={this.handleSubmit.bind(this)}>
                  <div className="form-group">
                    <input type="text" className="form-control" onChange={(e) => this.handleData(e, "name")} placeholder="Name" required />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Organization Name" required onChange={(e) => this.handleData(e, "organizationName")} />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Designation" required onChange={(e) => this.handleData(e, "designation")} />
                  </div>
                  <div className="form-group">
                    <input type="number" minLength={10} className="form-control" placeholder="Contact Number" required onChange={(e) => this.handleData(e, "ContactNumber")} />
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control" placeholder="Email Address" required onChange={(e) => this.handleData(e, "email")} />
                  </div>

                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Subject" required onChange={(e) => this.handleData(e, "subject")} />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Present Requirement" required onChange={(e) => this.handleData(e, "message")} />
                  </div>
                  <button className="btn btn-primary" type="submit">
                    send
                  </button>
                </form>
              </div>
              <div className="text-right">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <img alt="WTT..." src="assets/images/close.png" className="img-fluid" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal12" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="content text-center">
                <h5>Enter your mail id</h5>
                <form onSubmit={this.handleSubmitEmail.bind(this)}>
                  <div className="form-group">
                    <input type="email" onChange={(e) => this.setState({ emailId: e.target.value })} className="form-control" placeholder="Email" required />
                  </div>
                  <button className="btn btn-primary" type="submit">
                    send
                  </button>
                </form>
              </div>
              <div className="text-right">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <img alt="WTT..." src="assets/images/close.png" className="img-fluid" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <footer className="text-left">
          <div className="container-fluid px-0">
            <div className="row no-gutters">
              <div className="col-md-4 light-blue">
                <div className="footer-logo">
                  <img src={this.state.logo} alt="footer-logo" className="mb-4" />
                  <p style={{ fontSize: "15px", color: "#959595" }}>{this.state.logo_after_text}</p>
                </div>
              </div>
              <div className="col-md-8 dark-blue">
                <div className="footer-service">
                  <div className="row no-gutters">
                    <div className="col-md-6 pr-2">
                      {/* <h4>Services</h4> */}
                      <ul>
                        {this.state.page_link.map((PageData, index) => {
                          return (
                            <li key={index} className="footer-link">
                              <NavLink to={PageData.url}>{PageData.title}</NavLink>
                            </li>
                          );
                        })}
                        {/* <li>
                          <a href="">Download</a>
                        </li>
                        {this.state.Features.map((FeaturesData, index) => {
                          return (
                            <p key={index}>
                              {FeaturesData}
                              <br />
                            </p>
                          );
                        })} */}
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <h4>Touch with us</h4>
                      <p className="pt-md-1">
                        <b>{this.state.company_name}</b>
                        <br />
                        <b>Address :</b> {this.state.address}
                        <br />
                        {/* <br /> */}
                        <b>Phone :</b> {this.state.phone} <br />
                        {/* <br /> */}
                        <b>Email :</b> {this.state.email}
                      </p>
                      <h4 className="mt-3 mt-md-5">Follow Us</h4>
                      <div className="row no-gutters">
                        <div className="col-lg-1 col-2">
                          <a href={this.state.fbUrl} target="_blank">
                            <i className="fab fa-facebook"></i>
                          </a>
                        </div>
                        <div className="col-lg-1 col-2">
                          <a href={this.state.linkedinUrl} target="_blank">
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </div>
                        {this.state.youtubedata !== {} && this.state.youtubedata.status && (
                          <div className="col-lg-1 col-2">
                            <a href={this.state.youtubedata.url} target="_blank" rel="noopener noreferrer">
                              <i className="fab fa-youtube whatsapp-icon"></i>
                            </a>
                          </div>
                        )}
                        {this.state.instadata !== {} && this.state.instadata.status && (
                          <div className="col-lg-1 col-2">
                            <a href={this.state.instadata.url} target="_blank" rel="noopener noreferrer">
                              <i className="fab fa-instagram whatsapp-icon"></i>
                            </a>
                          </div>
                        )}
                        {this.state.twitterdata !== {} && this.state.twitterdata.status && (
                          <div className="col-lg-1 col-2">
                            <a href={this.state.twitterdata.url} target="_blank" rel="noopener noreferrer">
                              <i className="fab fa-twitter whatsapp-icon"></i>
                            </a>
                          </div>
                        )}

                        <div className="col-5">
                          <p style={{ fontSize: "14px", margin: "0px" }}>| WTT International</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="scrolltop" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
          <i className="fa fa-angle-up" />
        </div>
        <div className="copy-right">
          <div className="container py-3">
            <div className="row text-center">
              <div className="col-md-12">
                <p className="m-0" style={{ fontSize: "14px" }}>
                  Copyright © {this.getYear()} All rights reserved | WTT INTERNATIONAL PVT LTD
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
