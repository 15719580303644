import React from "react";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import ProductBanner from "./Products/ProductBanner";
import ProductList from "./Products/ProductList";
import Page from "./WTTLoading";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
          <Header />
          <ProductBanner />
          <ProductList />
          <Footer />
        </Page>
      </div>
    );
  }
}

export default App;
