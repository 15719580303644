import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import WhoWe from "./Explore/WhoWe";
// import SureForPure  from './Explore/SureForPure'
// import Clients  from './Explore/Clients'
// import ClientsList  from './Explore/ClientsList'
import Timeline from "./Explore/Timeline";
import Mission from "./Explore/Mission";
import Vision from "./Explore/Vision";
import Values from "./Explore/Values";
import OurTeam from "./Explore/OurTeam";
import Page from "./WTTLoading";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
          <Header />
          <br />
          <br />
          <br />
          <WhoWe />
          <Timeline />
          <Mission />
          <Vision />
          <Values />
          <OurTeam />
          {/* <SureForPure />
      <Clients />
      <ClientsList /> */}
          <Footer />
        </Page>
      </div>
    );
  }
}

export default App;
