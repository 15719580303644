import React from 'react';
import './App.css';
import Header from './Header'  
import Footer from './Footer' 
import IndustriesBanner from './Industries/IndustriesBanner'  
import IndustriesList from './Industries/IndustriesList' 
import ClientsInner from './Industries/ClientsInner' 
import CustomerInner from './Industries/CustomerInner' 
import Page from "./WTTLoading"

class App extends React.Component {
 render(){ 
   return (
    <div className="App">
        <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
      <Header/>      
      <IndustriesBanner />
      <IndustriesList />
      {/* <ClientsInner />
      <CustomerInner /> */}
      <Footer/>
      </Page>
    </div>
  );
}
}


export default App;
