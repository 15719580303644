import React, { useState } from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

class ProcessList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title: "",
      sub_title_content: [],
      errorMessage: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/Process?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title: response.data.data.sub_title,
          sub_title_content: response.data.data.sub_title_content,
        });
    });
  }

  render() {
    return (
      <section className="process pt-5">
        <div className="container">
          <div className="animatedParent">
            <h1 className="text-center animated fadeInUp m-0">{this.state.sub_title}</h1>
          </div>
          {this.state.sub_title_content.length ? (
            <div className="row pb-md-5">
              {this.state.sub_title_content.map((object, index) => {
                return (
                  <div className="col-md-4" key={index}>
                    <div className="card">
                      <img src={object.image} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <div className="card-title">
                          <h5>{object.title}</h5>
                        </div>
                        <p className="process-description">{object.text}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

export default ProcessList;
