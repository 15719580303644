import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// project imports
import GuestGuard from "./../utils/route-guard/GuestGuard";
import MinimalLayout from "./../layout/MinimalLayout";
import NavMotion from "./../layout/NavMotion";
import Loadable from "../ui-component/Loadable";

// login routing
const AuthLogin = Loadable(lazy(() => import("../views/pages/authentication/login")));
const AuthRegister = Loadable(lazy(() => import("../views/pages/authentication/register")));
const ForgotPassword = Loadable(lazy(() => import("../views/pages/authentication/login/ForgotPassword")));
const VerifyOTP = Loadable(lazy(() => import("../views/pages/authentication/login/VerifyOTP")));
const UpdatePasswordAfter = Loadable(lazy(() => import("../views/pages/authentication/login/UpdatePasswordAfter")));
//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
  const location = useLocation();

  return (
    <Route path={["/Admin/login", "/Admin/register", "/Admin/VerifyOTP", "/Admin/UpdatePasswordAfter", "/Admin/ForgotPassword"]}>
      <MinimalLayout>
        <Switch location={location} key={location.pathname}>
          <NavMotion>
            <GuestGuard>
              <Route path="/Admin/login" component={AuthLogin} />
              <Route path="/Admin/register" component={AuthRegister} />
              <Route path="/Admin/VerifyOTP/:email" component={VerifyOTP} />
              <Route path="/Admin/ForgotPassword" component={ForgotPassword} />
              <Route path="/Admin/UpdatePasswordAfter/:email" component={UpdatePasswordAfter} />
            </GuestGuard>
          </NavMotion>
        </Switch>
      </MinimalLayout>
    </Route>
  );
};

export default LoginRoutes;
