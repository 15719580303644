import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

class OurTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Ourteam: [],
      VideoIndex: 0,
      TempData: {},
    };
  }

  componentDidMount() {
    axios.get("/wtt/findParticularTeams?language=English").then((response) => {
      if (response.data) {
        this.setState({
          Ourteam: response.data.data.ourteams,
        });
      }
    });
  }

  render() {
    return (
      <>
        <div className="history animatedParent my-2 my-md-5">
          <div className="container">
            <h2 className="text-left animated fadeInLeft">Our Team</h2>
            <div className="row">
              {this.state.Ourteam.slice(this.state.VideoIndex, this.state.VideoIndex + 8).map((item, index) => {
                return (
                  <Zoom key={index}>
                    <div className="col-md-4 my-3">
                      <div className="team-card">
                        <img src={item.imgUrl} className="img-fluid mb-2" />
                        <h3>{item.name}</h3>
                        <p>{item.role}</p>
                        <div className="text-right">
                          <button
                            className="btn mt-3 text-light"
                            onClick={() => {
                              this.setState({
                                TempData: item,
                              });
                            }}
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            Know More
                          </button>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                );
              })}
            </div>
            <div className="d-flex justify-content-between mt-3">
              {this.state.VideoIndex !== 0 ? (
                <div className="text-left next">
                  <span
                    onClick={() => {
                      if (this.state.VideoIndex !== 0) {
                        this.setState({ VideoIndex: this.state.VideoIndex - 8 });
                      }
                    }}
                    style={{ fontWeight: 600 }}
                  >
                    <i className="pl-2 far fa-arrow-left mr-2"></i>Prev
                  </span>
                </div>
              ) : null}
              {this.state.VideoIndex + 8 < this.state.Ourteam.length && (
                <div className="text-right next">
                  <span
                    onClick={() => {
                      if (this.state.VideoIndex + 8 < this.state.Ourteam.length) {
                        this.setState({ VideoIndex: this.state.VideoIndex + 8 });
                      }
                    }}
                    style={{ fontWeight: 600 }}
                  >
                    Next<i className="pl-2 far fa-arrow-right"></i>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="content">
                <img src={this.state.TempData.imgUrl} className="img-fluid mb-2" />
                <h2>{this.state.TempData.name}</h2>
                <h4>{this.state.TempData.role}</h4>
                <p>{this.state.TempData.description}</p>
              </div>
              <div className="text-right">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <img alt="WTT..." src="assets/images/close.png" className="img-fluid" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurTeam;
