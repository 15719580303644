import React from "react";
import axios from "axios";

class NewsDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title_types: [],
      content: [],
      indexValue: 0,
      subIndexValue: 0,
      status: true,
      subStatus: true,
    };
  }
  componentDidMount() {
    axios.get("/wtt/NewsMedia?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title_types: response.data.data.sub_title_types,
          content: response.data.data.content,
        });
    });
  }

  handleIndex = (i) => {
    this.setState({
      indexValue: i,
      status: !this.state.status,
    });
  };
  handleSubIndex = (i) => {
    this.setState({
      subIndexValue: i,
      subStatus: !this.state.subStatus,
    });
  };

  render() {
    return (
      <section className="news">
        {this.state.sub_title_types.length ? (
          <div className="container-fluid p-0">
            <div className="row text-center no-gutters justify-content-center news-media">
              {this.state.sub_title_types.map((TypeName, index) => {
                return (
                  <div key={index} className="col-md-4 col-4">
                    <div className="card">
                      <div className="card-header" id={"heading" + (this.state.indexValue + 1)}>
                        <h6 className="mb-0">
                          <a className={this.state.indexValue === index ? "product-button active" : "product-button"} type="button" onClick={() => this.handleIndex(index)} href="#">
                            {TypeName}
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="bg-light">
              <div className="container">
                <div className="py-3 py-md-5">
                  {this.state.content[this.state.sub_title_types[this.state.indexValue]].type === 0 && (
                    <div className="animated fadeInUp go">
                      <div className="row releases">
                        <div className="col-md-3 pr-3">
                          <div className="row text-center">
                            <div className="col-md-12">
                              <h4>Featured Stories </h4>
                              {this.state.content[this.state.sub_title_types[this.state.indexValue]].message_types.map((TypeMessage, idx) => {
                                return (
                                  <div className="card">
                                    <div className="card-header" id={"headi" + (this.state.subIndexValue + 1)}>
                                      <h6 className="mb-0">
                                        <a className={this.state.subIndexValue === idx ? "news-release active1" : "news-release"} onClick={() => this.handleSubIndex(idx)} type="button">
                                          {TypeMessage}
                                        </a>
                                      </h6>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9 pl-md-5">
                          <div className={this.state.subStatus ? "animated fadeInUp go" : "animated fadeInDown go"}>
                            <h2>{this.state.content[this.state.sub_title_types[this.state.indexValue]].message_types[this.state.subIndexValue]}</h2>
                            <p>{this.state.content[this.state.sub_title_types[this.state.indexValue]].text}</p>
                            <p>Date published: {this.state.content[this.state.sub_title_types[this.state.indexValue]].date}</p>
                            {this.state.content[this.state.sub_title_types[this.state.indexValue]][this.state.content[this.state.sub_title_types[this.state.indexValue]].message_types[this.state.subIndexValue]].map((MessageData, indx) => {
                              return (
                                <div key={indx} className="row pt-3">
                                  <div className="col-md-4">
                                    <img alt="WTT..." src={MessageData.image} className="img-fluid" />
                                  </div>
                                  <div className="col-md-8">
                                    <h5>{MessageData.heading}</h5>
                                    <p style={{ fontSize: "17px" }}>
                                      {MessageData.text}
                                      <a style={{ textDecoration: "underline" }} href={MessageData.url} target="_blank">
                                        Read More
                                      </a>
                                    </p>
                                    <h6>{MessageData.after_text}</h6>
                                  </div>
                                </div>
                              );
                            })}
                            {/* <div className="text-right next">
                              <a href="" style={{ FontWeight: "600" }}>
                                Next<i className="pl-2 far fa-arrow-right"></i>
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.content[this.state.sub_title_types[this.state.indexValue]].type === 1 && (
                    <div>
                      {this.state.content[this.state.sub_title_types[this.state.indexValue]].message.map((SubData, index) => {
                        return (
                          <div className="press-coverage" key={index}>
                            <div className="animated fadeInUp go row p-3 releases">
                              <div className="col-md-4">
                                <img alt="WTT..." src={SubData.image} className="img-fluid" style={{ borderRadius: "25px" }} />
                              </div>
                              <div className="col-md-8 align-self-center">
                                <h5>{SubData.heading}</h5>
                                <p style={{ fontSize: "17px" }}>
                                  {SubData.text}
                                  <a style={{ textDecoration: "underline" }} href={SubData.url} target="_blank">
                                    Read More
                                  </a>
                                </p>
                                <h6>{SubData.after_text}</h6>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {this.state.content[this.state.sub_title_types[this.state.indexValue]].type === 2 && (
                    <div className="blogs my-md-5">
                      {this.state.content[this.state.sub_title_types[this.state.indexValue]].message.map((SubData, index) => {
                        return (
                          <div key={index} className="card mb-3  releases">
                            <div className="row no-gutters justify-content-center animated fadeInUp go">
                              <div className="col-md-3 d-flex align-items-center">
                                <img alt="WTT..." src={SubData.image} className="card-hori img-fluid" />
                              </div>
                              <div className="col-md-8 blog-post">
                                <div className="card-body">
                                  <h5>{SubData.heading}</h5>
                                  <p style={{ fontSize: "17px" }}>
                                    {SubData.text}
                                    <a style={{ textDecoration: "underline" }} href={SubData.url} target="_blank">
                                      Read More
                                    </a>
                                  </p>
                                  <h6>{SubData.after_text}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}
export default NewsDescription;
