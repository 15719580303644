import React from "react";
import axios from "axios";

class GalleryBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      background_image: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/findParticularGallery?language=English").then((response) => {
      if (response.data)
        this.setState({
          title: response.data.data.title,
          content: response.data.data.content,
          backgroundImage: response.data.data.backgroundImage,
        });
    });
  }

  render() {
    return (
      <section className="hero-banner1" style={{ background: "url(" + this.state.backgroundImage + ")" }}>
        <div className="container">
          <div className="pt-5">
            <div className="row pt-md-5 mt-md-5">
              <div className="col-md-6 pt-md-5 mt-md-5 pb-3">
                <div className="banner-content1 text-center">
                  <h1>{this.state.title}</h1>
                  <p>{this.state.content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default GalleryBanner;
