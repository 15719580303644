import React from "react";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Clients",
      content: [],
      indexValue: 0,
    };
  }
  componentDidMount() {
    axios.get("/wtt/findAllClientImage").then((response) => {
      if (response.data)
        this.setState({
          content: response.data.data,
        });
    });
  }

  render() {
    const options = {
      loop: true,
      nav: true,
      navText: ["<i className='fa fa-chevron-left'></i>", "<i className='fa fa-chevron-right'></i>"],
      dots: false,
      autoplay: true,
      margin: 30,
      autoplayTimeout: 4500,
      autoplaySpeed: 1000,
      lazyLoad: true,
      singleitem: "true",
      items: 3,
      responsive: {
        0: {
          items: 1,
        },
        767: {
          items: 2,
        },
        1024: {
          items: 2,
        },
        1100: {
          items: 3,
        },
      },
    };
    let Data = [];
    let { title, content } = this.state;
    content.map((item, index) => {
      const temp = (
        <img
          style={{
            height: "250px",
            width: "250px",
            backgroundColor: "white",
            objectFit: "contain",
          }}
          alt={item.title}
          key={index}
          src={item.image}
        />
      );
      Data.push(temp);
      return temp;
    });
    if (content.length) {
      return (
        <section className="testimonial clients-button animatedParent">
          {content.length ? (
            <div className="container ">
              <div className="row mb-5">
                <div className="col-md-6">
                  <div className="news-title animated fadeInLeft">
                    <h2 className="animated fadeInLeft">{title}</h2>
                  </div>
                </div>
                <div className="col-md-6 align-self-center text-right">
                  <a href="/AllClients">See All</a>
                </div>
              </div>

              <div className="mt-5">
                {content.length && (
                  <OwlCarousel className="owl-theme" {...options}>
                    {Data}
                  </OwlCarousel>
                )}
              </div>
            </div>
          ) : null}
        </section>
      );
    } else {
      return <></>;
    }
  }
}

export default Clients;
