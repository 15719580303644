import React from "react";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import CSRBanner from "./CSR/CSRBanner";
import CSRProject from "./CSR/CSRProject";
import CSRMessage from "./CSR/CSRMessage";
import CSRStory from "./CSR/CSRStory";
import Page from "./WTTLoading";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Page loader={"rotate-spin"} color={"#A9A9A9"} size={8}>
          <Header />
          <CSRBanner />
          <CSRMessage />
          <CSRProject />
          <CSRStory />
          <Footer />
        </Page>
      </div>
    );
  }
}

export default App;
