import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

class IndustriesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sub_title: [],
      sub_title_content: [],
      sub_title1: [],
      sub_title1_content: [],
      errorMessage: "",
      TempTitle: "",
      TempContent: "",
    };
  }
  componentDidMount() {
    axios.get("/wtt/IndustriesWeServe?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title: response.data.data.sub_title,
          sub_title_content: response.data.data.sub_title_content,
          sub_title1: response.data.data.sub_title1,
          sub_title1_content: response.data.data.sub_title1_content,
        });
    });
  }
  render() {
    return (
      <div>
        <section className="industry animatedParent py-5">
          <div className="container">
            <h2>{this.state.sub_title}</h2>
            <div className="row animated fadeInUp py-md-5 pb-4">
              {this.state.sub_title_content.map((object, index) => (
                <Zoom key={index}>
                  <div className="col-md-4 mb-3">
                    <div className="industry-image">
                      <img alt="WTT..." src={object.image} className="img-fluid" />
                    </div>
                    <h3>{object.title}</h3>
                    <button
                      onClick={() => {
                        this.setState({
                          TempTitle: object.title,
                          TempContent: object.message,
                        });
                      }}
                      className="btn btn-primary readmore"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Read More
                      <img alt="WTT..." src="assets/images/arrow.png" className="img-fluid" style={{ width: "13%" }} />
                    </button>
                  </div>
                </Zoom>
              ))}
            </div>
          </div>
        </section>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="content">
                <h2>{this.state.TempTitle}</h2>
                <p>{this.state.TempContent}</p>
              </div>
              <div className="text-right">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <img alt="WTT..." src="assets/images/close.png" className="img-fluid" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IndustriesList;
