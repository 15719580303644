import React from "react";
import axios from "axios";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";

class RoundeCircle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      centerLogo: "",
      surronding_images: [],
      background_image: "",
    };
  }

  componentDidMount() {
    axios.get("/wtt/HomePage?language=English").then((response) => {
      if (response.data)
        this.setState({
          title: response.data.data.title,
          centerLogo: response.data.data.centerLogo,
          surronding_images: response.data.data.surronding_images,
          background_image: response.data.data.background_image,
        });
    });
  }
  render() {
    return (
      <section className="rounded-image animatedParent pb-5" style={{ background: `url(${this.state.background_image})` }}>
        <h2 className="text-center animated fadeInDown">{this.state.title}</h2>
        {this.state.surronding_images.length ? (
          <Zoom>
            <ul className="circle-container">
              {this.state.surronding_images.map((image, index) => {
                return (
                  <li key={index}>
                    <img alt="WTT..." src={image} />
                  </li>
                );
              })}
              <li className="center-img-container">
                <img alt="WTT..." src={this.state.centerLogo} className="center-img" />
              </li>
            </ul>
          </Zoom>
        ) : null}
      </section>
    );
  }
}

export default RoundeCircle;
