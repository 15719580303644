import React from "react";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import AwardsBanner from "./Awards/AwardsBanner";
import AwardsContent from "./Awards/AwardsContent";
import Page from "./WTTLoading";

class Awards extends React.Component {
  render() {
    return ( 
      <div className="App">
        <Page loader={'rotate-spin'} color={'#A9A9A9'} size={8}>
          <Header />
          <AwardsBanner />
          <AwardsContent />
          <Footer />
        </Page>
      </div>
    );
  }
}

export default Awards;
