import React from "react";
import axios from "axios";

class CSRStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      sub_title1_videos: [],
      VideoIndex: 0,
    };
  }
  componentDidMount() {
    axios.get("/wtt/CSR?language=English").then((response) => {
      if (response.data)
        this.setState({
          sub_title1: response.data.data.sub_title1,
          sub_title1_videos: response.data.data.sub_title1_videos,
        });
    });
  }

  render() {
    return (
      <section className="csr-stories">
        {this.state.sub_title1_videos.length ? (
          <div className="container">
            <h1 className="text-center">{this.state.sub_title1}</h1>
            <div className="row pt-3 pt-md-5">
              {this.state.sub_title1_videos.slice(this.state.VideoIndex, this.state.VideoIndex + 3).map((object, index) => (
                <div className="col-md-4 mb-4 mb-md-5" key={index}>
                  <iframe src={object} />
                  {/* <video
                      id='my-video'
                      className='video-js'
                      controls
                      preload='auto'
                      width='340'
                      height='280'
                      poster='assets/images/global1.png'
                      style={{ borderRadius: '50px' }}
                      data-setup='{}'>
                      <source src={object} type='video/mp4' />
                      <source src={object} type='video/webm' />
                      <p className='vjs-no-js'>
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href='https://videojs.com/html5-video-support/'
                          target='_blank'>
                          supports HTML5 video
                        </a>
                      </p>
                    </video> */}
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-between">
              {this.state.VideoIndex !== 0 ? (
                <div className="text-left next">
                  <span
                    onClick={() => {
                      if (this.state.VideoIndex !== 0) {
                        this.setState({
                          VideoIndex: this.state.VideoIndex - 3,
                        });
                      }
                    }}
                    style={{ fontWeight: 600 }}
                  >
                    <i className="pl-2 far fa-arrow-left"></i>Prev
                  </span>
                </div>
              ) : null}
              {this.state.VideoIndex + 3 < this.state.sub_title1_videos.length && (
                <div className="text-right next">
                  <span
                    onClick={() => {
                      if (this.state.VideoIndex + 3 < this.state.sub_title1_videos.length) {
                        this.setState({
                          VideoIndex: this.state.VideoIndex + 3,
                        });
                      }
                    }}
                    style={{ fontWeight: 600 }}
                  >
                    Next<i className="pl-2 far fa-arrow-right"></i>
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default CSRStory;
